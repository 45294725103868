/*
<summary>
  This functional component "LogoutPopup" provides modal popup that shows logout confirmation message (i.e. Are you sure
  you want logout?).
  Developer:Aashish Singh, Created Date:28-Aug-2022, Last Updated By: Mohammad Saquib Khan, Updated Date: 26-Oct-2022
</summary>
<returns>Returns JSX</returns>
*/

import React, { useEffect } from "react";
import { Modal, Button } from "react-bootstrap";
import "./logout-popup.css";
import { FormattedMessage } from "react-intl";
import { toast } from "react-toastify";
import { observer } from "mobx-react";
import RoutesConstants from "../../../shared-components/routes/routes-constants";
import { useStore } from "../../../contexts/store-provider";
import { useHistory } from "react-router-dom";
import { formatMessage } from "../../../translations/format-message";
import { getTenantIdFromToken } from "../../../helpers/localstorage-helper";

interface IProps {
  modalClosed: () => void;
}

const LogoutPopup = (props: IProps) => {
  const history = useHistory();
  const { authStore, userStore, tenantStore, preferencesStore } = useStore();
  const { logout, logoutError, logoutSuccess, logoutInprogress, resetLogout } = authStore;
  const { setTenantAccess, resetMediaAccess, resetPriceSchedulerAccess } = preferencesStore;

  const resetStores = () => {
    tenantStore.resetStore();
    userStore.reset();
  }

  useEffect(() => {
    if (logoutError) {
      toast.error(formatMessage(logoutError));
      resetLogout();
    }
  }, [logoutError]);

  useEffect(() => {
    if (logoutSuccess) {
      toast.success(formatMessage("logout_successfully"));
      resetLogout();
      history.push(RoutesConstants.Login);
      props.modalClosed();
      resetStores();
    }
  }, [logoutSuccess]);

  const logoutCaller = () => {
    authStore.logout();
    // setUpdateTheme("false");
  };


  return (
    <Modal
      show={true}
      centered
      onHide={props.modalClosed}
      dialogClassName="no-data-found-popup"
    >
      <Modal.Header closeButton>
        <Modal.Title>
          <FormattedMessage id="logout" />
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
      <span className="modal-wave">
                    <svg
                      width="447"
                      height="116"
                      viewBox="0 0 447 116"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        opacity="0.4"
                        d="M55.2678 22.3777C-49.5465 -14.1611 7.16534 -48.8529 136.242 -34.0647L214.579 -30.0724L448.26 -8.82579L459.956 104.858C396.401 148.386 406.862 51.7166 297.501 67.1292C188.139 82.5419 225.278 33.322 176.928 20.0906C128.579 6.8592 91.4243 34.9821 55.2678 22.3777Z"
                        fill="rgba(126, 166, 255, 0.4)"
                      ></path>
                    </svg>
                  </span>
        <div className="bootbox-body p-3">

          <div className="logout_modal_icon">
          <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 24 24" fill="#fff"><path fill="#fff" d="M13.24 2c.47 0 .86.38.86.86v18.29c0 .47-.38.86-.86.86-5.89 0-10-4.11-10-10S7.36 2 13.24 2z" opacity=".4"></path><path fill="#fff" d="M20.54 11.54L17.7 8.69a.754.754 0 00-1.06 0c-.29.29-.29.77 0 1.06l1.56 1.56H8.63c-.41 0-.75.34-.75.75s.34.75.75.75h9.57l-1.56 1.56c-.29.29-.29.77 0 1.06.15.15.34.22.53.22s.38-.07.53-.22l2.84-2.85a.73.73 0 000-1.04z"></path></svg>

          </div>
          <div className="mt-3">
            <FormattedMessage id="logout_message" />
          </div>
        </div>
        <span className="footer-wave">
                    <svg
                      width="333"
                      height="61"
                      viewBox="0 0 333 61"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        opacity="0.4"
                        d="M-0.322477 0.641086L-0.418408 0.55164L-9.20939 59.4297L23.6588 106.206L154.575 130.423C236.759 117.931 383.93 93.3326 315.142 94.879C246.355 96.4253 215.362 64.2785 215.362 64.2785C215.362 64.2785 185.497 26.9117 117.864 33.4279C42.6115 40.6783 10.6143 10.8399 -0.322477 0.641086Z"
                        fill="rgba(126, 166, 255, 0.4)"
                      ></path>
                    </svg>
                  </span>
      </Modal.Body>
      <Modal.Footer>
                  <div className="d-flex justify-content-center ">
        <Button
          variant="light-danger"
          onClick={props.modalClosed}
          className="btnOkSize me-2"
        >
          <FormattedMessage id="button_cancel" />
        </Button>
        <Button variant="primary" onClick={logoutCaller} className="btnOkSize">
          <FormattedMessage id="logout" />
        </Button>
      </div>
      </Modal.Footer>
    </Modal>
  );
};

export default observer(LogoutPopup);
