import { PermissionType } from "../constants/enums/permission-type-enum";
import userTypeEnum from "../constants/enums/user-type-enum";
import authStore from "../core/stores/auth-store";
import { IActionFormatter } from "../models/ICommon";
import { IRoleVM } from "../models/response/IRoleResponse";

export const showIconsRoleBase = (AddIcons: IActionFormatter[], module: number): IActionFormatter[] => {
  let userPermission: any = authStore.getAllPermission;
  let userRole: any = authStore.getUserType;
  let icons: IActionFormatter[] = AddIcons;
  if (userRole === userTypeEnum[4]) {
    return icons;
  }
  else {
    if (JSON.stringify(userPermission) === "[]") {
      const permissions = localStorage.getItem('kms_permissions');
      if (permissions)
        userPermission = JSON.parse(permissions);
    }
    let modulePermission = userPermission.filter((per: IRoleVM) => per.ModuleType == module);
    if (modulePermission?.length === 1) {
      if (modulePermission[0]?.PermissionType == PermissionType.ViewOnly)
        icons = icons.filter((icon: IActionFormatter) => (icon.action !== 'edit') && icon.action !== 'delete');
    }
    else if (modulePermission[0]?.PermissionType == PermissionType.Hide) {
      icons = icons.filter((icon: IActionFormatter) => (icon.action !== 'edit') && icon.action !== 'delete' && icon.action !== 'view');
    }
    return icons;
  }
}

export const isOperationPermittedRoleBase = (module: number, action: any) => {
  let predefinedActions = ['Add', 'Edit', 'Delete'];
  let userPermission: any = authStore.getAllPermission;
  let userRole: any = authStore.getUserType;
  if (userRole === userTypeEnum[4]) {
    return true;
  }
  else {
    if (JSON.stringify(userPermission) === "[]") {
      const permissions = localStorage.getItem('kms_permissions');
      if (permissions)
        userPermission = JSON.parse(permissions);
    }
    let allAccess = predefinedActions.filter((data: string) => data == action);
    let modulePermission = userPermission.filter((per: IRoleVM) => per.ModuleType == module);
    if (modulePermission.length === 1 && allAccess.length > 0)
      return modulePermission[0].PermissionType == PermissionType.AllAccess;
    return false;
  }
}

export const isOperationPermittedParkingRoleBase = (module: number, action: string) => {
  let allAccessActions = [
    {
      PermissionRequired: PermissionType.AllAccess,
      PermissionText: "Add",
    },
    {
      PermissionRequired: PermissionType.AllAccess,
      PermissionText: "Edit",
    },
    {
      PermissionRequired: PermissionType.AllAccess,
      PermissionText: "Delete",
    },
  ];
  let hideActions = [
    {
      PermissionRequired: PermissionType.Hide,
      PermissionText: "Hide",
    },
  ];
  let viewActions = [
    {
      PermissionRequired: PermissionType.ViewOnly,
      PermissionText: "View",
    },
  ];

  let allAccess = allAccessActions.filter((data: any) => data.PermissionText == action);
  let hide = hideActions.filter((data: any) => data.PermissionText == action);
  let viewOnly = viewActions.filter((data: any) => data.PermissionText == action);

  let userPermission: any = authStore.getAllPermission;
  let userRole: any = authStore.getUserType;
  if (userRole == userTypeEnum[4]) {
    if (action == 'Hide') {
      return false;
    }
    else if (action == ('Add' || 'Edit' || 'Delete')) {
      return true;
    }
    else {
      return true;
    }
  }
  else {
    if (JSON.stringify(userPermission) === "[]") {
      const permissions = localStorage.getItem('kms_permissions');
      if (permissions)
        userPermission = JSON.parse(permissions);
    }

    let modulePermission = userPermission.filter((per: IRoleVM) => per.ModuleType == module);
    if (modulePermission.length === 1) {
      if (allAccess?.length == 1 && modulePermission[0].PermissionType == allAccess[0].PermissionRequired) {
        return true;
      }
      else if (hide?.length == 1 && modulePermission[0].PermissionType == hide[0].PermissionRequired) {
        return true;
      }
      else if (viewOnly?.length == 1 && modulePermission[0].PermissionType == viewOnly[0].PermissionRequired) {
        return true;
      }
      else {
        return false;
      }
    }
    return false;
  }
}
