/*
<summary>
  This functional component "AddUserForm" provides form to add new user or update existing user.
  Developer:Aashish Singh, Created Date:29-March-2023
</summary>
<returns>Returns JSX</returns>
*/
import { useState } from "react";
import { Form, Formik, Field } from "formik";
import "./add-edit-system-user-form.css";
import { FormattedMessage } from "react-intl";
import { Modal, Button } from "react-bootstrap";
import { Form as BsForm } from 'react-bootstrap';
import { IOption } from "../../../../models/ICommon";
import LoaderButton from "../../../../shared-components/button/loader-button";
import Label from "../../../../shared-components/label/label";
import FormikFormInput from "../../../../shared-components/FormikFormHandlers/FormikFormInput";
import FormikFormSelect from "../../../../shared-components/FormikFormHandlers/FormikFormSelect";
import FormLoader from "../../../../shared-components/FormLoader/FormLoader";
import { AddSystemUserValidateSchema, EditSystemUserValidateSchema } from "../../../../shared-components/FormikFormHandlers/FormikValidationSchema";
import { getTenantIdFromLocalStorage, getUserType } from "../../../../helpers/localstorage-helper";
import userTypeEnum from "../../../../constants/enums/user-type-enum";
import validCharacterPassword from "../../../../constants/valid-character-password";


interface IProps {
  id: number;
  userControlOptions: IOption[];
  tenantOptions: IOption[];
  submitHandler: (data: any) => void;
  onModalClosed: () => void;
  getUserLoading: boolean;
  addUpdateUserLoading: boolean;
  initialValues: any;
}

const AddSystemUserForm = (props: IProps) => {
  const [showHidePassword, setShowHidePassword] = useState(false);
  const [showHideConfirmPassword, setShowHideConfirmPassword] = useState(false);
  const [showHideChangePassword, setShowHideChangePassword] = useState(false);

  return (
    <>
      <Modal
        className="kitchen-modal fancy-modal add-device-modal"
        size="lg"
        show={true}
        centered={true} onHide={() => { }}>
        <Modal.Header>
          <Modal.Title>
            {props.id === -1 ? (
              getUserType() === 'CompanyAdmin' ?
                <FormattedMessage id="add_kms_user" />
                :
                <FormattedMessage id="add_system_user" />
            ) : (
              <FormattedMessage id="update_system_user" />
            )}
            <button
              onClick={props.onModalClosed}
              disabled={props.addUpdateUserLoading}
              className="Crossicons"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="23"
                height="23"
                fill="currentColor"
                className="bi bi-x"
                viewBox="0 0 16 16"
              >
                <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
              </svg>
            </button>
          </Modal.Title>
        </Modal.Header>
        <Formik
          initialValues={getTenantIdFromLocalStorage() === "-1" ? props.initialValues : { ...props.initialValues, TenantId: props.tenantOptions[1]?.id || -1 }}
          enableReinitialize
          validationSchema={
            props.id === -1 ? AddSystemUserValidateSchema : EditSystemUserValidateSchema
          }
          validateOnBlur={false}
          onSubmit={async (values) => {
            props.submitHandler(values);
          }}
        >
          {({ dirty }) => {
            return (
              <Form className="user-form">
                {props.getUserLoading ? (
                  <FormLoader loading={props.getUserLoading} />
                ) : null}
                <Modal.Body>
                  <span className="modal-wave">
                    <svg
                      width="447"
                      height="116"
                      viewBox="0 0 447 116"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        opacity="0.4"
                        d="M55.2678 22.3777C-49.5465 -14.1611 7.16534 -48.8529 136.242 -34.0647L214.579 -30.0724L448.26 -8.82579L459.956 104.858C396.401 148.386 406.862 51.7166 297.501 67.1292C188.139 82.5419 225.278 33.322 176.928 20.0906C128.579 6.8592 91.4243 34.9821 55.2678 22.3777Z"
                        fill="rgba(126, 166, 255, 0.4)"
                      ></path>
                    </svg>
                  </span>

                  <div className="row">
                    <div className="col-md-6 col-12">
                      <div className="form-group">
                        <Label
                          className="label-style"
                          required={true}
                          label="user_label_login_id"
                        />
                        <Field
                          data-testid="testEmail"
                          className="form-control"
                          name="Email"
                          type="text"
                          disabled={props.id > 0 ? true : false}
                          invalidcharacter={[" "]}
                          validcharacter={[
                            "-",
                            "_",
                            "[",
                            "]",
                            "(",
                            ")",
                            ":",
                            "#",
                            "@",
                            ".",
                          ]}
                          placeholder=""
                          as={FormikFormInput}
                        />
                      </div>
                    </div>
                    <div className="col-md-6 col-12">
                      <Label
                        className="label-style"
                        required={true}
                        label="display_name"
                      />
                      <Field
                        data-testid="Username"
                        className="form-control"
                        name="UserName"
                        type="text"
                        validcharacter={["@", "#", "$", "-", "_", "."]}
                        placeholder=""
                        as={FormikFormInput}
                      />
                    </div>
                    <div className="col-md-6 col-12">
                      <div className="form-group">
                        <Label
                          className="label-style"
                          required={true}
                          label="user_type"
                        />
                        <Field
                          name="UserType"
                          as={FormikFormSelect}
                          options={props.userControlOptions}
                        />
                      </div>
                    </div>
                    {
                      <div className="col-md-6 col-12">
                        <div className="form-group">
                          <Label
                            className="label-style "
                            required={true}
                            label="company"
                          />
                          <Field
                            name="TenantId"
                            as={FormikFormSelect}
                            disabled={props.id !== -1 || getTenantIdFromLocalStorage() !== "-1"}
                            options={props.tenantOptions}
                            setDefaultValue={true}
                          />
                        </div>
                      </div>
                    }

                    {props.id === -1 && (
                      <>
                        <div className="col-md-6 col-12 password-icon">
                          <div className="form-group">
                            <Label
                              className="label-style"
                              required={true}
                              label="user_password"
                            />
                            <div>
                              <Field
                                data-testid="testPassword"
                                className="form-control"
                                name="Password"
                                autoComplete="new-password"
                                type={showHidePassword ? "text" : "password"}
                                invalidcharacter={[" "]}
                                validcharacter={validCharacterPassword}
                                placeholder=""
                                as={FormikFormInput}
                              />
                              {showHidePassword ? (
                                <svg
                                  type="button"
                                  onClick={() => {
                                    setShowHidePassword(!showHidePassword);
                                  }}
                                  viewBox="0 0 24 24"
                                  width="20"
                                  height="20"
                                  stroke="currentColor"
                                  strokeWidth="2"
                                  fill="none"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  className="showHidePass css-i6dzq1 modalBox"
                                >
                                  <path d="M1 12s4-8 11-8 11 8 11 8-4 8-11 8-11-8-11-8z"></path>
                                  <circle cx="12" cy="12" r="3"></circle>
                                </svg>
                              ) : (
                                <svg
                                  type="button"
                                  onClick={() => {
                                    setShowHidePassword(!showHidePassword);
                                  }}
                                  viewBox="0 0 24 24"
                                  width="20"
                                  height="20"
                                  stroke="currentColor"
                                  strokeWidth="2"
                                  fill="none"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  className="showHidePass css-i6dzq1 modalBox"
                                >
                                  <path d="M17.94 17.94A10.07 10.07 0 0 1 12 20c-7 0-11-8-11-8a18.45 18.45 0 0 1 5.06-5.94M9.9 4.24A9.12 9.12 0 0 1 12 4c7 0 11 8 11 8a18.5 18.5 0 0 1-2.16 3.19m-6.72-1.07a3 3 0 1 1-4.24-4.24"></path>
                                  <line x1="1" y1="1" x2="23" y2="23"></line>
                                </svg>
                              )}
                            </div>
                          </div>
                        </div>
                        <div className="col-md-6 col-12 password-icon">
                          <div className="form-group">
                            <Label
                              className="label-style"
                              required={true}
                              label="confirm_password"
                            />
                            <div>
                              <Field
                                data-testid="testConfirmPassword"
                                className="form-control"
                                name="ConfirmPassword"
                                autoComplete="confirm-new-password"
                                type={showHideConfirmPassword ? "text" : "password"}
                                invalidcharacter={[" "]}
                                validcharacter={validCharacterPassword}
                                placeholder=""
                                as={FormikFormInput}
                              />
                              {showHideConfirmPassword ? (
                                <svg
                                  type="button"
                                  onClick={() => {
                                    setShowHideConfirmPassword(!showHideConfirmPassword);
                                  }}
                                  viewBox="0 0 24 24"
                                  width="20"
                                  height="20"
                                  stroke="currentColor"
                                  strokeWidth="2"
                                  fill="none"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  className="showHidePass css-i6dzq1 modalBox"
                                >
                                  <path d="M1 12s4-8 11-8 11 8 11 8-4 8-11 8-11-8-11-8z"></path>
                                  <circle cx="12" cy="12" r="3"></circle>
                                </svg>
                              ) : (
                                <svg
                                  type="button"
                                  onClick={() => {
                                    setShowHideConfirmPassword(!showHideConfirmPassword);
                                  }}
                                  viewBox="0 0 24 24"
                                  width="20"
                                  height="20"
                                  stroke="currentColor"
                                  strokeWidth="2"
                                  fill="none"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  className="showHidePass css-i6dzq1 modalBox"
                                >
                                  <path d="M17.94 17.94A10.07 10.07 0 0 1 12 20c-7 0-11-8-11-8a18.45 18.45 0 0 1 5.06-5.94M9.9 4.24A9.12 9.12 0 0 1 12 4c7 0 11 8 11 8a18.5 18.5 0 0 1-2.16 3.19m-6.72-1.07a3 3 0 1 1-4.24-4.24"></path>
                                  <line x1="1" y1="1" x2="23" y2="23"></line>
                                </svg>
                              )}
                            </div>
                          </div>
                        </div>
                      </>
                    )}

                    {/* {props.id !== -1
                      && (
                        props.initialValues.UserType === userTypeEnum.CompanyUser
                        || (props.initialValues.UserType === userTypeEnum.CompanyAdmin && getTenantIdFromLocalStorage() === "-1")
                      )
                      &&
                      (
                        <>
                          {
                            showHideChangePassword &&
                            <div className="col-md-6 col-12 password-icon">
                              <div className="form-group">
                                <Label
                                  className="label-style"
                                  required={true}
                                  label="new_password"
                                />
                                <div>
                                  <Field
                                    data-testid="testPassword"
                                    className="form-control"
                                    name="Password"
                                    autoComplete="new-password"
                                    type={showHidePassword ? "text" : "password"}
                                    invalidcharacter={[" "]}
                                    validcharacter={validCharacterPassword}
                                    placeholder=""
                                    as={FormikFormInput}
                                  />
                                  {showHidePassword ? (
                                    <svg
                                      type="button"
                                      onClick={() => {
                                        setShowHidePassword(!showHidePassword);
                                      }}
                                      viewBox="0 0 24 24"
                                      width="20"
                                      height="20"
                                      stroke="currentColor"
                                      strokeWidth="2"
                                      fill="none"
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                      className="showHidePass css-i6dzq1 modalBox"
                                    >
                                      <path d="M1 12s4-8 11-8 11 8 11 8-4 8-11 8-11-8-11-8z"></path>
                                      <circle cx="12" cy="12" r="3"></circle>
                                    </svg>
                                  ) : (
                                    <svg
                                      type="button"
                                      onClick={() => {
                                        setShowHidePassword(!showHidePassword);
                                      }}
                                      viewBox="0 0 24 24"
                                      width="20"
                                      height="20"
                                      stroke="currentColor"
                                      strokeWidth="2"
                                      fill="none"
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                      className="showHidePass css-i6dzq1 modalBox"
                                    >
                                      <path d="M17.94 17.94A10.07 10.07 0 0 1 12 20c-7 0-11-8-11-8a18.45 18.45 0 0 1 5.06-5.94M9.9 4.24A9.12 9.12 0 0 1 12 4c7 0 11 8 11 8a18.5 18.5 0 0 1-2.16 3.19m-6.72-1.07a3 3 0 1 1-4.24-4.24"></path>
                                      <line x1="1" y1="1" x2="23" y2="23"></line>
                                    </svg>
                                  )}
                                </div>
                              </div>
                            </div>
                          }
                          <div className="col-md-12 col-12">
                            <div className="form-group change-password">
                              <BsForm.Check
                                type="switch"
                                id="custom-switch"
                                label="Change Password?"
                                onClick={() => setShowHideChangePassword(!showHideChangePassword)}
                              />
                            </div>
                          </div>
                        </>
                      )} */}
                  </div>

                  <span className="footer-wave">
                    <svg
                      width="333"
                      height="61"
                      viewBox="0 0 333 61"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        opacity="0.4"
                        d="M-0.322477 0.641086L-0.418408 0.55164L-9.20939 59.4297L23.6588 106.206L154.575 130.423C236.759 117.931 383.93 93.3326 315.142 94.879C246.355 96.4253 215.362 64.2785 215.362 64.2785C215.362 64.2785 185.497 26.9117 117.864 33.4279C42.6115 40.6783 10.6143 10.8399 -0.322477 0.641086Z"
                        fill="rgba(126, 166, 255, 0.4)"
                      ></path>
                    </svg>
                  </span>

                </Modal.Body>
                <Modal.Footer>

                  <div className="d-flex justify-content-center ">
                    <div className="row tab-action g-0">
                      <div className="col-12">
                        <Button
                          variant="light-danger"
                          type="button"
                          onClick={props.onModalClosed}
                          disabled={props.addUpdateUserLoading}
                        >
                          <FormattedMessage id="button_cancel" />
                        </Button>
                        <LoaderButton
                          type="submit"
                          isLoading={props.addUpdateUserLoading}
                          text="button_save"
                        />
                      </div>
                    </div>
                  </div>
                </Modal.Footer>
              </Form>
            )
          }}
        </Formik>
      </Modal>
    </>
  );
};

export default AddSystemUserForm;
