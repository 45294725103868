import { jwtDecode } from "jwt-decode";

export const getUserType = () => localStorage.getItem("kms_userType");

export const getToken = () => localStorage.getItem("kms_token");

export const getRefreshToken = () => localStorage.getItem("kms_refreshToken");

export const getUserName = () => localStorage.getItem("kms_userName");

export const getUserId = () => localStorage.getItem("kms_userId");

export const getParamType = () => localStorage.getItem("kms_paramType");

export const getTimeZone = () => localStorage.getItem("kms_timezoneId");

export const getdebuglogsfilter = () => localStorage.getItem("kms_debugLogFilter");

export const getTenantIdFromLocalStorage = () =>
  localStorage.getItem("kms_access_tenant_id");

export const getTokenFromLocalStorage = () => {
  let token = localStorage.getItem("kms_token");
  return token ? token : "";
};

export const getRoleNameFromLocalStorage = () => {
  let roleName = localStorage.getItem("kms_role_name");
  return roleName ? roleName : "";
};

export const getTenantIdFromToken = () => {
  let token = localStorage.getItem("kms_token");
  let tenantId: any = 0;
  if (token !== null && token !== "") {
    const userToken: any = jwtDecode(token);
    tenantId = userToken.tenantId;
  }
  return parseInt(tenantId);
}

export const getOrgIdFromToken = () => {
  let token = localStorage.getItem("kms_token");
  let orgId: any = "";
  if (token !== null && token !== "") {
    const userToken: any = jwtDecode(token);
    orgId = userToken.organizationId;
  }
  return orgId;
}

export const getUserIdFromToken = () => {
  let token = localStorage.getItem("kms_token");
  let userId: any = 0;
  if (token !== null && token !== "") {
    const userToken: any = jwtDecode(token);
    userId = userToken.userId;
  }
  return parseInt(userId);
}

export const getProductIdFromToken = () => {
  // let token = localStorage.getItem("kms_token");
  // let userId:any = 0;
  // if (token !== null && token !== "") {
  //   const userToken: any = jwtDecode(token);
  //   userId = userToken.userId;
  // }
  // return parseInt(userId);
  return 0;
}

export const getUserEmailFromToken = () => {
  let token = localStorage.getItem("kms_token");
  let userEmail: string = "";
  if (token !== null && token !== "") {
    const userToken: any = jwtDecode(token);
    userEmail = userToken.userEmail;
  }
  return userEmail;
}

export const getTenantAccessFromLocalStorage = (): any => {
  let tenantId = localStorage.getItem("kms_access_tenant_id");
  return tenantId ? parseInt(tenantId) : -1;
}

export const getIsTenantAccessFromLocalStorage = (): any => {
  let isTenantAccess = localStorage.getItem("kms_is_access_tenant");
  return isTenantAccess ? JSON.parse(isTenantAccess) : false;
}

export const getUserTypeFromLocalStorage = () => {
  let userType = localStorage.getItem('kms_userType');
  return userType ? userType : "";
}