/*
<summary>
   This functional common component provides us custom input box & has error handler that shows validation error.
   Developer:Aashish Singh, Created Date:08-Mar-2023
</summary>
<returns>Returns JSX</returns>
*/
import React, { useState } from "react";
import { injectIntl } from "react-intl";
import FormikFormErrorHandler from "./FormikFormErrorHandler";
import { useField } from "formik";
import FormikRelayInfoFormErrorHandler from "./FormikFormRelayErrorHandler";

interface IProps {
  children: React.ReactNode;
  className: string;
  errclassname: string | undefined;
  "data-testid"?: string;
  intl: any; //IntlConfig;
  name: string;
  onBlur: () => void;
  onChange: () => void;
  placeholder: string;
  type: string;
  value: string;
  disabled: boolean;
  readOnly: boolean;
  spaceAllowed: boolean;
  validcharacter?: Array<string>;
  invalidcharacter?: Array<string>;
}

const FormikFormInput = (props: IProps) => {
  const [field, meta, helpers] = useField(props);
  const errorClass = `${meta.error && meta.touched ? "error" : ""}`;
  const { validcharacter, invalidcharacter } = props;
  const [value, setValue] = useState<any>("");
  const _placeholder = props.placeholder
    ? props.intl.formatMessage({ id: props.placeholder })
    : props.placeholder;

  let subStrArr = [
    "?",
    ">",
    "<",
    "'",
    '"',
    ":",
    ";",
    ",",
    "+",
    "-",
    "(",
    ")",
    "*",
    "&",
    "^",
    "%",
    "$",
    "#",
    "{",
    "[",
    "]",
    "}",
    "|",
    "/",
    "\\",
    "=",
    "_",
    "!",
    "~",
    "`",
    ".",
    "@"
  ];
  if (invalidcharacter) invalidcharacter.forEach((el) => subStrArr.push(el));
  if (validcharacter)
    subStrArr = subStrArr.filter((el) => !validcharacter.includes(el));

  const handleKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
    const keyCode = event.keyCode || event.which;
    const keyValue = String.fromCharCode(keyCode);

    // Allow only numeric input (0-9)
    if (!/^\d+(\.\d+)?$/.test(keyValue)) {
      event.preventDefault();
    }
  };
  return (
    <>
      <input
        {...field}
        {...props}
        placeholder={_placeholder}
        className={props.className + " " + errorClass}
        disabled={props.disabled}
        type={props.type}
        readOnly={props.readOnly}
        onKeyDown={
          props.type === "number"
            ? (evt) =>
              ["e", "E", "+", "-"].includes(evt.key) && evt.preventDefault()
            : undefined
        }
        onKeyPress={
          props.type === "number"
            ? (evt) => {
              handleKeyPress(evt);
            }
            : undefined
        }
        onChange={(event) => {
          if (props.type == "number") {
            const inputValue = event.target.value;

            // Check if the input matches the allowed pattern
            if (/^\d*\.?\d*$/.test(inputValue)) {

              helpers.setValue(inputValue);
            }
          }
          else {

            if (
              !props.spaceAllowed &&
              (event.target.value === "" ||
                !subStrArr.some((subStr: any) =>
                  event.target.value.includes(subStr)
                ))
            ) {
              helpers.setValue(event.target.value);
            } else if (
              (props.spaceAllowed === undefined || props.spaceAllowed) &&
              (event.target.value === "" ||
                !subStrArr.some((subStr: any) =>
                  event.target.value.includes(subStr)
                ))
            ) {
              helpers.setValue(event.target.value);
            }
          }
        }}
      />
      {props.errclassname != undefined ||
        props.errclassname == "relay_max_character_error" ? (
        <FormikRelayInfoFormErrorHandler intl={props.intl} meta={meta} />
      ) : (
        <FormikFormErrorHandler intl={props.intl} meta={meta} />
      )}
    </>
  );
};

export default injectIntl(FormikFormInput);
