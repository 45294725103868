import React, { useEffect, useState } from "react";
import "./add-edit-tenant-form.css";
import { FormattedMessage } from "react-intl";
import { Field, Form, Formik } from "formik";
import { Button, Modal, Tooltip } from "react-bootstrap";
import { observer } from "mobx-react";
import FormLoader from "../../../shared-components/FormLoader/FormLoader";
import FormikFormInput from "../../../shared-components/FormikFormHandlers/FormikFormInput";
import LoaderButton from "../../../shared-components/button/loader-button";
import IAddTenant from "../../../models/forms/IAddUpdateTenant";
import FormikFormCheckBox from "../../../shared-components/FormikFormHandlers/FormikFormCheckBox";
import FormikFormInputForRegexCheck from "../../../shared-components/FormikFormHandlers/FormikFormInputForRegexCheck";
import FormikFormTextArea from "../../../shared-components/FormikFormHandlers/FormikFormTextArea";
import Label from "../../../shared-components/label/label";
import { formatMessage } from "../../../translations/format-message";
import { permissionTypeOptions, systemUserTypeOptions } from "../../../constants/options.constant";
import FormikFormSelect from "../../../shared-components/FormikFormHandlers/FormikFormSelect";
import { AddUserValidateSchema, EditUserValidateSchema, tenantDetailValidateSchema } from "../../../shared-components/FormikFormHandlers/FormikValidationSchema";

interface IProps {
  Id: number;
  initialValues: IAddTenant;
  submitHandler: (data: any) => void;
  isDataLoading: boolean;
  isLoading: boolean;
  modalClosed: () => void;
  isUpdate: boolean;
}

const AddUpdateTenantForm = (props: IProps) => {
  const { initialValues, submitHandler, isLoading, modalClosed, isUpdate } = props;
  const [data, setData] = useState({ ...initialValues });
  const [currentStep, setCurrentStep] = useState<number>(0);

  useEffect(() => {
    setData({ ...initialValues });
  }, [initialValues]);

  const handleNextStep = (newData: any, final: boolean = false) => {
    setData((prev: any) => ({ ...prev, ...newData }));
    if (final) {
      let tempValues: IAddTenant = { ...newData };
      submitHandler(tempValues);
      return;
    }
    setCurrentStep((prev: any) => prev + 1);
  };

  const handlePrevStep = (newData: any) => {
    setData((prev: any) => ({ ...prev, ...newData }));
    setCurrentStep((prev: any) => prev - 1);
  };

  const StepOne = (props1: any) => {
    const handleSubmit = (values: any) => {
      if (values.CreateUser) {
        props1.next({ ...values });
      } else {
        // If CreateUser is false, submit the form directly
        props1.next({ ...values }, true);
        // props.submitHandler(values);
      }
    };

    return (
      <Formik
        initialValues={props1.data}
        onSubmit={handleSubmit}
        validationSchema={tenantDetailValidateSchema}
        validateOnBlur={false}
        enableReinitialize
      >
        {
          ({ values, isValid, handleSubmit, handleBlur, handleChange, errors, touched }) => (
            <Form id="formBody" onSubmit={handleSubmit}>
              {
                props.isDataLoading || isLoading &&
                <FormLoader loading={props.isDataLoading || isLoading} />
              }

              <Modal.Body>
                <span className="modal-wave">
                  <svg
                    width="447"
                    height="116"
                    viewBox="0 0 447 116"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      opacity="0.4"
                      d="M55.2678 22.3777C-49.5465 -14.1611 7.16534 -48.8529 136.242 -34.0647L214.579 -30.0724L448.26 -8.82579L459.956 104.858C396.401 148.386 406.862 51.7166 297.501 67.1292C188.139 82.5419 225.278 33.322 176.928 20.0906C128.579 6.8592 91.4243 34.9821 55.2678 22.3777Z"
                      fill="rgba(126, 166, 255, 0.4)"
                    ></path>
                  </svg>
                </span>
                <div className="row">
                  <div className="col-md-4 col-12">
                    <div className="form-group formSet">
                      <Label className="label-style" required={true} label="tenant_name" />
                      <Field
                        data-testid="OrganizationName"
                        className="form-control"
                        name="OrganizationName"
                        placeholder=""
                        type="text"
                        validcharacter={["@", "#", "$", "-", "_", "."]}
                        as={FormikFormInput}
                        onBlur={handleBlur}
                        onChange={handleChange}
                      />
                    </div>
                  </div>

                  <div className="col-md-4 col-12">
                    <div className="form-group formSet">
                      <Label className="label-style" required={true} label="tenant_nickname" />
                      <Field
                        data-testid="OrganizationNickName"
                        className="form-control"
                        name="OrganizationNickName"
                        placeholder=""
                        type="text"
                        validcharacter={["@", "#", "$", "-", "_", "."]}
                        as={FormikFormInput}
                        onBlur={handleBlur}
                        onChange={handleChange}
                      />
                    </div>
                  </div>

                  <div className="col-md-4 col-12">
                    <div className="form-group formSet">
                      <Label className="label-style" label="phone" />
                      <Field
                        data-testid="Phone"
                        className="form-control"
                        name="Phone"
                        placeholder=""
                        invalidcharacter={[" "]}
                        as={FormikFormInput}
                        type="number"
                        onBlur={handleBlur}
                        onChange={handleChange}
                      />
                    </div>
                  </div>

                  <div className="col-12">
                    <div className="form-group formSet">
                      <Label className="label-style" required={false} label="address" />
                      <Field
                        rows={3}
                        id="Address"
                        className="form-control"
                        name="Address"
                        placeholder=""
                        type="text"
                        as={FormikFormTextArea}
                      />
                    </div>
                  </div>

                  <div className="col-12">
                    <div className="form-group formSet">
                      <Label className="label-style" required={false} label="description" />
                      <Field
                        rows={3}
                        id="Comment"
                        className="form-control"
                        name="Description"
                        placeholder=""
                        type="text"
                        as={FormikFormTextArea}
                      />
                    </div>
                  </div>
                  <span className="footer-wave">
                    <svg
                      width="333"
                      height="61"
                      viewBox="0 0 333 61"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        opacity="0.4"
                        d="M-0.322477 0.641086L-0.418408 0.55164L-9.20939 59.4297L23.6588 106.206L154.575 130.423C236.759 117.931 383.93 93.3326 315.142 94.879C246.355 96.4253 215.362 64.2785 215.362 64.2785C215.362 64.2785 185.497 26.9117 117.864 33.4279C42.6115 40.6783 10.6143 10.8399 -0.322477 0.641086Z"
                        fill="rgba(126, 166, 255, 0.4)"
                      ></path>
                    </svg>
                  </span>

                </div>
              </Modal.Body>
              <Modal.Footer>
                <div className="d-flex justify-content-center">
                  <div className="row tab-action g-0">
                    <div className="col-12">
                      <Button
                        variant="light-danger"
                        type="button"
                        onClick={props.modalClosed}
                        disabled={props.isDataLoading}
                      >
                        <FormattedMessage id="button_cancel" />
                      </Button>
                      <LoaderButton
                        type="submit"
                        id="SaveTenant"
                        isLoading={props.isLoading}
                        text={!isUpdate ? "save" : "update"}
                      />
                    </div>
                  </div>
                </div>
              </Modal.Footer>
            </Form>
          )}
      </Formik>
    )
  }

  // const StepTwo = (props2: any) => {
  //   const [showHidePassword, setShowHidePassword] = useState(false);
  //   const handleSubmit = (values: any) => {
  //     props.submitHandler(values);
  //     // props2.next(values, props.Id === -1 ? false : true);
  //   }
  //   return (
  //     <Formik
  //       initialValues={props2.data}
  //       onSubmit={handleSubmit}
  //       validationSchema={props.Id === -1 ? AddUserValidateSchema : EditUserValidateSchema}
  //       validateOnBlur={false}
  //       enableReinitialize
  //     >
  //       {
  //         ({ values }) => {
  //           return (
  //             <Form id="formBody">
  //               {
  //                 props.isDataLoading || isLoading &&
  //                 <FormLoader loading={props.isDataLoading || isLoading} />
  //               }
  //               <div className="pt-3">
  //                 <div className="progress px-1 step-progress">
  //                   <div className={`progress-bar ${props.Id === -1 ? "w-50" : "w-100"}`} role="progressbar"></div>
  //                 </div>
  //                 <div className="step-container d-flex justify-content-between">
  //                   <div className="step-circle completed">1 <span className="step-detail"><FormattedMessage id="tenant_details" /></span></div>
  //                   <div className="step-circle active">2 <span className="step-detail"><FormattedMessage id="user_details" /></span></div>
  //                 </div>
  //               </div>
  //               <div className="row">
  //                 <div className="col-md-6 col-12">
  //                   <div className="form-group formSet">
  //                     <Label
  //                       className="label-style col-sm-4 col-form-label"
  //                       required={true}
  //                       label="user_label_login_id" />
  //                     <Field
  //                       data-testid="testEmail"
  //                       className="form-control"
  //                       name="Email"
  //                       type="text"
  //                       invalidcharacter={[" "]}
  //                       validcharacter={[
  //                         "-",
  //                         "_",
  //                         "[",
  //                         "]",
  //                         "(",
  //                         ")",
  //                         ":",
  //                         "#",
  //                         "@",
  //                         ".",
  //                       ]}
  //                       placeholder="user_label_login_id"
  //                       as={FormikFormInput} />
  //                   </div>
  //                 </div>

  //                 <div className="col-md-6 col-12">
  //                   <div className="form-group formSet">
  //                     <Label
  //                       className="label-style col-sm-4 col-form-label"
  //                       required={true}
  //                       label="display_name" />
  //                     <Field
  //                       data-testid="Username"
  //                       className="form-control"
  //                       name="UserName"
  //                       type="text"
  //                       validcharacter={["@", "#", "$", "-", "_", "."]}
  //                       placeholder="display_name"
  //                       as={FormikFormInput} />
  //                   </div>
  //                 </div>

  //                 <div className="col-md-6 col-12">
  //                   <div className="form-group formSet">
  //                     <Label
  //                       className="label-style col-sm-4 col-form-label"
  //                       required={true}
  //                       label="user_type" />
  //                     <Field
  //                       name="UserType"
  //                       as={FormikFormSelect}
  //                       options={systemUserTypeOptions} />
  //                   </div>
  //                 </div>

  //                 {
  //                   // props.Id === -1 && (
  //                   //   <div className="col-md-6 col-12">
  //                   //     <div className="form-group formSet position-relative">
  //                   //       <Label
  //                   //         className="label-style col-sm-8 col-form-label"
  //                   //         required={true}
  //                   //         label="user_password"
  //                   //       />
  //                   //       <Field
  //                   //         data-testid="testPassword"
  //                   //         className="form-control"
  //                   //         name="Password"
  //                   //         autoComplete="new-password"
  //                   //         type={showHidePassword ? "text" : "password"}
  //                   //         invalidcharacter={[" "]}
  //                   //         validcharacter={[
  //                   //           "~",
  //                   //           "`",
  //                   //           "!",
  //                   //           "@",
  //                   //           "#",
  //                   //           "$",
  //                   //           "%",
  //                   //           "^",
  //                   //           "&",
  //                   //           "*",
  //                   //           "(",
  //                   //           ")",
  //                   //           "_",
  //                   //           "-",
  //                   //           "+",
  //                   //           "=",
  //                   //           "{",
  //                   //           "[",
  //                   //           "}",
  //                   //           "]",
  //                   //           "|",
  //                   //           "\\",
  //                   //           ":",
  //                   //           ";",
  //                   //           "'",
  //                   //           '"',
  //                   //           "<",
  //                   //           ",",
  //                   //           ">",
  //                   //           ".",
  //                   //           "?",
  //                   //           "/",
  //                   //         ]}
  //                   //         placeholder="user_password"
  //                   //         as={FormikFormInput}
  //                   //       />
  //                   //       {showHidePassword ? (
  //                   //         <svg
  //                   //           type="button"
  //                   //           onClick={() => {
  //                   //             setShowHidePassword(!showHidePassword);
  //                   //           }}
  //                   //           viewBox="0 0 24 24"
  //                   //           width="20"
  //                   //           height="20"
  //                   //           stroke="currentColor"
  //                   //           strokeWidth="2"
  //                   //           fill="none"
  //                   //           strokeLinecap="round"
  //                   //           strokeLinejoin="round"
  //                   //           className="showHidePassTenant css-i6dzq1 modalBox"
  //                   //         >
  //                   //           <path d="M1 12s4-8 11-8 11 8 11 8-4 8-11 8-11-8-11-8z"></path>
  //                   //           <circle cx="12" cy="12" r="3"></circle>
  //                   //         </svg>
  //                   //       ) : (
  //                   //         <svg
  //                   //           type="button"
  //                   //           onClick={() => {
  //                   //             setShowHidePassword(!showHidePassword);
  //                   //           }}
  //                   //           viewBox="0 0 24 24"
  //                   //           width="20"
  //                   //           height="20"
  //                   //           stroke="currentColor"
  //                   //           strokeWidth="2"
  //                   //           fill="none"
  //                   //           strokeLinecap="round"
  //                   //           strokeLinejoin="round"
  //                   //           className="showHidePassTenant css-i6dzq1 modalBox"
  //                   //         >
  //                   //           <path d="M17.94 17.94A10.07 10.07 0 0 1 12 20c-7 0-11-8-11-8a18.45 18.45 0 0 1 5.06-5.94M9.9 4.24A9.12 9.12 0 0 1 12 4c7 0 11 8 11 8a18.5 18.5 0 0 1-2.16 3.19m-6.72-1.07a3 3 0 1 1-4.24-4.24"></path>
  //                   //           <line x1="1" y1="1" x2="23" y2="23"></line>
  //                   //         </svg>
  //                   //       )}
  //                   //     </div>
  //                   //   </div>
  //                   // )
  //                 }

  //               </div>
  //               <div className="pb-3 mt-4 d-flex justify-content-center">
  //                 <Button
  //                   data-testid="back"
  //                   variant="light-secondary"
  //                   onClick={() => { props2.prev(values); }}
  //                   disabled={props.isLoading}
  //                   className="mr-3"
  //                 >
  //                   <FormattedMessage id="back" />
  //                 </Button>

  //                 <LoaderButton
  //                   type="submit"
  //                   id="SaveTenant"
  //                   className="ms-2"
  //                   isLoading={props.isLoading}
  //                   text="button_save" />

  //               </div>
  //             </Form>
  //           );
  //         }
  //       }
  //     </Formik>
  //   )
  // }

  const steps = [
    <StepOne next={handleNextStep} data={data} isLoading={isLoading} Id={props.Id} />,
    // <StepTwo submitHandler={submitHandler} prev={handlePrevStep} data={data} isLoading={isLoading} Id={props.Id} />,
  ];

  return (
    <div>
      <div>{steps[currentStep]}</div>
    </div>
  );
};

export default AddUpdateTenantForm;
