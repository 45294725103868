/*
<summary>
  This functional component "UserProfilePopUp" defines the props like initial values and functions for the child form
  component.
  Developer:Aashish Singh, Created Date:28-Aug-2022, Last Updated By: Mohammad Saquib Khan, Updated Date: 26-Oct-2022
</summary>
<returns>Returns JSX</returns>
*/

import React from "react";
import UserProfile from "./components/user-profile";
interface IProps {
  userName: string;
  userType: string;
  modalClosed: () => void;
}
const UserProfilePopUp: React.FC<IProps> = (props) => {
  /**
   * This function closes the popup model
   */
  const onModalClosed = () => {
    props.modalClosed();
  };

  return (
    <React.Fragment>
      <UserProfile
        userName={props.userName}
        userType={props.userType}
        onModalClosed={onModalClosed}
      />
    </React.Fragment>
  );
};

export default UserProfilePopUp;
