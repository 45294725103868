/*
<summary>
   This functional component ensures that important configurations are provided or made available to the subcomponents
   in the tree.
   Developer:Aashish Singh, Created Date:29-March-2023
</summary>
<param>No Parameter Passed</param>
<returns>Returns JSX</returns>
*/
import { IntlProvider, ReactIntlErrorCode } from "react-intl";
import * as React from "react";
import translations from "./translation";
import { observer } from "mobx-react";
import { useStore } from "../contexts/store-provider";

type Props = {
  children?: React.ReactNode;
};

const onError = (e: any) => {
  if (e.code === ReactIntlErrorCode.MISSING_DATA) {
    return;
  }
  console.error(e);
};

const IntlProviderNew = ({ children }: Props) => {
  const { preferencesStore } = useStore();
  localStorage.setItem("kms_language", preferencesStore.language);
  return (
    <IntlProvider
      onError={onError}
      defaultLocale="jp"
      locale={preferencesStore.language}
      messages={translations[preferencesStore.language].messages}
    >
      {children}
    </IntlProvider>
  );
};

export default observer(IntlProviderNew);
