/*
<summary>
  This functional component "AddPopupHOC" provides props to the child component.
  1. State "showAddPopup" that manages the show/hide of pop up
  2. Function "addToggleHandler" to toggle the show/hide popup state "showAddPopup"
  Developer:Mohammad Saquib Khan, Created Date:02-April-2024
</summary>
<returns>Returns JSX</returns>
*/
import React, { useState } from "react";

const AddSystemUserPopupHOC = (Component: any) =>
  function Comp(props: any) {
    const [showAddSystemUserPopup, setShowAddSystemUserPopup] = useState<boolean>(false);

    const addSystemUserToggleHandler = () => {
      setShowAddSystemUserPopup(!showAddSystemUserPopup);
    };

    return (
      <Component
        showAddSystemUserPopup={showAddSystemUserPopup}
        addSystemUserToggleHandler={addSystemUserToggleHandler}
        {...props}
      />
    );
  };

export default AddSystemUserPopupHOC;
