import React from "react";
import { Redirect, Switch, withRouter } from "react-router-dom";
import LoginLayoutRoute from "../layout/login-layout-route";
import LayoutRoute from "../layout/layout-route";
import { observer } from "mobx-react";
import RoutesConstants from "./routes-constants";
import Login from "../../modules/auth/login";
import KmsUser from "../../modules/user/kms-user";
import SystemUser from "../../modules/user/system-user";
import Tenant from "../../modules/tenant/tenant";
import AccountActivation from "../../modules/account-activation/account-activation";

import { findRouteAccess, redirectToDefaultRoute } from "../../helpers/route-helper";
import { ModuleType } from "../../constants/enums/module-type-enum";
import Product from "../../modules/product/product";
import weightedProduct from "../../modules/product/weighted-product";

const Routes = () => {
    return (
        <React.Fragment>
            <Switch>
                <LoginLayoutRoute path={RoutesConstants.Login} component={Login} />
                <LayoutRoute path={RoutesConstants.AccountActivation} component={AccountActivation} />
                <LayoutRoute path={RoutesConstants.Products} component={Product} />
                <LayoutRoute path={RoutesConstants.WeightedProducts} component={weightedProduct} />
                {findRouteAccess(ModuleType.KmsUserManagement) && <LayoutRoute path={RoutesConstants.KmsUser} component={KmsUser} />}
                {findRouteAccess(ModuleType.CompanyUserManagement) && <LayoutRoute path={RoutesConstants.SystemUser} component={SystemUser} />}
                {findRouteAccess(ModuleType.TenantManagement) && <LayoutRoute path={RoutesConstants.TenantManagement} component={Tenant} />}
                <Redirect to={redirectToDefaultRoute()} />
            </Switch>
        </React.Fragment>
    );
};

export default withRouter(observer(Routes));
