/*
<summary>
    This functional common component provides modal popup that shows delete confirmation message (i.e. are you sure want to delete).
    Developer:Mohammad Saquib Khan, Created Date:04-April-2024
</summary>
<returns>Returns JSX</returns>
*/
import React from "react";
import { Modal, Button } from "react-bootstrap";
import { FormattedMessage } from "react-intl";
import LoaderButton from "../../button/loader-button";
import deleteImages from "../../../assets/Images/delete.png";
import "./delete-popup.css";
interface Props {
  modalSubmit: () => void;
  modalClosed: () => void;
  isLoading: boolean;
  title: string;
  message?: string;
}

const DeletePopup: React.FC<Props> = (props) => {
  return (
    <Modal
      show={true}
      centered
      dialogClassName="delete_popup"
      onHide={() => {}}
    >
      {/* <Modal.Header>
    
                <Modal.Title><FormattedMessage id={props.title} />
                    <button onClick={props.modalClosed} disabled={props.isLoading} className="Crossicons" >
                        <svg xmlns="http://www.w3.org/2000/svg" width="23" height="23" fill="currentColor" className="bi bi-x" viewBox="0 0 16 16">
                            <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
                        </svg>
                    </button>
                </Modal.Title>
            </Modal.Header>  */}
      <Modal.Body className="p-5">
        <span className="modal-wave">
          <svg
            width="447"
            height="116"
            viewBox="0 0 447 116"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              opacity="0.4"
              d="M55.2678 22.3777C-49.5465 -14.1611 7.16534 -48.8529 136.242 -34.0647L214.579 -30.0724L448.26 -8.82579L459.956 104.858C396.401 148.386 406.862 51.7166 297.501 67.1292C188.139 82.5419 225.278 33.322 176.928 20.0906C128.579 6.8592 91.4243 34.9821 55.2678 22.3777Z"
              fill="rgba(126, 166, 255, 0.4)"
            ></path>
          </svg>
        </span>
        <img src={deleteImages} alt="logo" className="img-fluid"></img>
        <Modal.Title className="my-3 ">
          <b>
            <FormattedMessage id={props.title} />
          </b>{" "}
        </Modal.Title>
        <div className="bootbox-body">
          <FormattedMessage id={props.message} />
        </div>
        <span className="footer-wave">
          <svg
            width="333"
            height="61"
            viewBox="0 0 333 61"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              opacity="0.4"
              d="M-0.322477 0.641086L-0.418408 0.55164L-9.20939 59.4297L23.6588 106.206L154.575 130.423C236.759 117.931 383.93 93.3326 315.142 94.879C246.355 96.4253 215.362 64.2785 215.362 64.2785C215.362 64.2785 185.497 26.9117 117.864 33.4279C42.6115 40.6783 10.6143 10.8399 -0.322477 0.641086Z"
              fill="rgba(126, 166, 255, 0.4)"
            ></path>
          </svg>
        </span>
      </Modal.Body>
      <Modal.Footer className="">
        <Button
          variant="light-danger"
          onClick={props.modalClosed}
          disabled={props.isLoading}
          className="btnOkSize"
        >
          <FormattedMessage id="button_cancel" />
        </Button>
        <LoaderButton
          type="submit"
          onClick={props.modalSubmit}
          isLoading={props.isLoading}
          text="button_confirm"
        />
      </Modal.Footer>
    </Modal>
  );
};

export default DeletePopup;
