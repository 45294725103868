import authStore, { AuthStore } from "./auth-store";
import preferencesStore, { PreferencesStore } from "./preferences-store";
import tenantStore, { TenantStore } from "./tenant-store";
import userStore, { UserStore } from "./user-store";
import logsStore, { LogsStore } from "./logs-store";
import productStore, { ProductStore } from "./product-store";



export type RootStore = {
  authStore: AuthStore;
  preferencesStore: PreferencesStore;
  userStore: UserStore;
  tenantStore: TenantStore;
  logsStore: LogsStore;
  productStore: ProductStore;
};

const rootStore: RootStore = {
  authStore,
  preferencesStore,
  userStore,
  logsStore,
  tenantStore,
  productStore
};

export default rootStore;
