/*
The value ofNODE_ENV is set automatically to development (when using npm start),
test (when using npm test) or production (when using npm build).
Thus, from the point of view of create-react-app, there are only three environments.
*/

const config = () => {
  const config: any = (window as any).Config;
  return {
    ...config, ...process.env
  };
};

export default config;
