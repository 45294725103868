/*
<summary>
   This is a common component that shows company information.
   Developer:Aashish Singh, Created Date:29-March-2023
</summary>
<param>No Parameter Passed</param>
<returns>Returns JSX</returns>
*/
import React from 'react';
import './footer.css';

const Footer: React.FC = () => {
    return (
        <footer className="footer">

        </footer>
    );
}

export default Footer;