/*
<summary>
   This file defines axios interceptors have request handler and error handler.
</summary>
*/
import axios, { AxiosResponse } from 'axios';
import { errorMessage } from '../../constants/error-constants';
import { AuthStore } from '../stores/auth-store';
import { clearLocalStorageFields } from '../../constants/constants';
import RoutesConstants from '../../shared-components/routes/routes-constants';
import config from '../../helpers/config-helper';

const appConfig = config();
export const baseURL = appConfig.REACT_APP_API_URL;
export const baseAPI = axios.create({ baseURL });
const requestHandler = (request: any) => {
    const token = new AuthStore().getToken;
    request.headers['authorization'] = 'Bearer ' + token;
    return request;
};
const errorHandler = async (error: any) => {

    let message: string = errorMessage.ErrorOccured;
    if (error.response) {
        if (error.response.status === 401 && error.response.data.Message != errorMessage.InvalidEmailOrPassword && error.response.data.Message != errorMessage.YourAccountInactive && error.response.data.Message != errorMessage.UserEmailNotFound) {
            message = errorMessage.Unauthorized;
            clearLocalStorageFields();
            setTimeout(() => { window.location.href = RoutesConstants.Login }, 2000);
            return Promise.reject(message);
        } else if (error.response.status === 403) {
            message = errorMessage.UnauthorizedAccess;
        }
        else if (error.response.data && error.response.data.Message && error.response.data.Message !== null && error.response.data.Message !== undefined) {
            message = error.response.data.Message
        }
    }
    return Promise.reject(message);
};

const successHandler = (response: AxiosResponse): AxiosResponse => {
    return response;
};

baseAPI.interceptors.request.use(request => requestHandler(request));
baseAPI.interceptors.response.use(
    response => successHandler(response),
    error => errorHandler(error)
);
