/*
<summary>
   This functional common component provides us pagination functionality.
   Developer:Aashish Singh, Created Date:25-Aug-2022
</summary>
<returns>Returns JSX</returns>
*/
import React, { useEffect, useState } from 'react'
import { Pagination } from 'react-bootstrap';
import { Search } from 'react-bootstrap-icons';
import './Pagination.css';
import { formatMessage } from '../../translations/format-message';


type PropTypes = {
    totalPages: number;
    pageLimit: number;
    onPageChanged: Function
    disablePagination: boolean;
    isRefreshPage: boolean;
    setIsRefreshPage: Function;
}
const ApplicationPagger = (props: PropTypes) => {
    const [currentPage, setCurrentPage] = useState<number>(1);
    const [inputCurrentPage, setInputCurrentPage] = useState<number>(1);

    let { totalPages, pageLimit, onPageChanged, disablePagination, isRefreshPage } = props;
    if (totalPages === 0) {
        totalPages = 1;
    }
    const gotoPage = (page: number) => {
        const changedPage = Math.max(1, Math.min(page, totalPages));
        setCurrentPage(changedPage);
    }

    useEffect(() => {
        if (totalPages === 1)
            setInputCurrentPage(1);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [totalPages])

    useEffect(() => {
        onPageChanged(1);
        setCurrentPage(1);
        setInputCurrentPage(1);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pageLimit])

    useEffect(() => {
        if (isRefreshPage) {
            setInputCurrentPage(1);
            setCurrentPage(1);
            props.setIsRefreshPage();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isRefreshPage])

    useEffect(() => {
        onPageChanged(currentPage);
        setInputCurrentPage(currentPage)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentPage])

    useEffect(() => {
        gotoPage(1);
        setInputCurrentPage(1);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleCurrentPage = () => {
        gotoPage(inputCurrentPage);
    }

    const handleMoveFirst = (e: any) => {
        e.preventDefault();
        gotoPage(1);

    }

    const handleMoveLast = (e: any) => {
        e.preventDefault();
        gotoPage(totalPages);
    }

    const handleMoveNext = (e: any) => {
        e.preventDefault();
        gotoPage(currentPage + 1);
    }

    const handleMovePrev = (e: any) => {
        e.preventDefault();
        gotoPage(currentPage - 1);

    }

    const onInputPageChanged = (e: any) => {
        let numRegex = /^[0-9\b]+$/;
        if (e.target.value === '' || numRegex.test(e.target.value)) {
            setInputCurrentPage(e.target.value)
        }
    }

    const inputChanged = (e: any) => {
        if (e.target.value === "")
            setInputCurrentPage(currentPage);
    }

    return (
        <div className="panelTablePagination">
            <Pagination>
                <Pagination.First as="span" onClick={handleMoveFirst} disabled={disablePagination || inputCurrentPage == 1} title={formatMessage("first_page")} />
                <Pagination.Prev as="span" onClick={handleMovePrev} disabled={disablePagination || inputCurrentPage == 1} title={formatMessage("previous")} />
                <div className='customPages'>
                    <span className='currentPage'>
                        <input type="text" name="currentPage" value={inputCurrentPage} className='pageInput' onChange={onInputPageChanged} onBlur={inputChanged} />
                        <span title={formatMessage("search")} className={(inputCurrentPage <= totalPages && inputCurrentPage > 0) ? "searchIcon" : "searchIcon disabled-class"} onClick={handleCurrentPage}><Search title={formatMessage("search")} /> </span>
                    </span>
                    <span className='totalpages' title={formatMessage("total_page")}> / {totalPages}</span>
                </div>
                <Pagination.Next as="span" data-testid="nextPage" onClick={handleMoveNext} disabled={disablePagination || inputCurrentPage == totalPages} title={formatMessage("next")} />
                <Pagination.Last as="span" onClick={handleMoveLast} disabled={disablePagination || inputCurrentPage == totalPages} title={formatMessage("last_page")} />

            </Pagination>
        </div>

    )
}

export default ApplicationPagger;