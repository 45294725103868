/*
<summary>
   This file is used to provide initial values or state to the User Data observables.
   Developer:Mohammad Saquib Khan, Created Date: 02-April-2024
</summary>
*/

import { IAddKmsUser } from "../../models/forms/IAddUser";
import { IChangePassword } from "../../models/forms/IChangePassword";


export const initialState: IAddKmsUser = {
    UserType: -1,
    Password: '',
    Email: '',
    UserName: '',
    AccountStatus: 0,
    TenantId: -1,
    ConfirmPassword: ''
}

export const ChangePasswordInitalState: IChangePassword = {
    NewPassword: "",
    ConfirmPassword: ""
}