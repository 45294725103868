/*
<summary>
   This typescript file contains special datatype that enables for a variale to be set of predefined constant.
   This constant is used in displaying error messages according to the error code recieved from the backend API.
      Developer: Mohammad Saquib Khan, Created Date: 02-April-2024
</summary>
*/

import { IImageOption, IOption } from "../models/ICommon";

export const userTypeOptions: IOption[] = [
  { id: -1, value: "please_select", disabled: true },
  { id: 0, value: "HCMS" },
  { id: 1, value: "Company" },
  { id: 2, value: "Branch" },
  { id: 3, value: "ParkingLot" },
];

export const permissionTypeOptions: IOption[] = [
  { id: -1, value: "please_select", disabled: true },
  { id: 0, value: "View Only" },
  { id: 1, value: "All Access" },
];

export const kmsUserTypeOptions: IOption[] = [
  { id: -1, value: "please_select", disabled: true },
  { id: 0, value: "KmsAdmin" },
  { id: 1, value: "KmsUser" },
];

export const systemUserTypeOptions: IOption[] = [
  { id: -1, value: "please_select", disabled: true },
  { id: 2, value: "CompanyAdmin" },
  { id: 3, value: "CompanyUser" },
];


export const ParkingLotInputType: IOption[] = [
  { id: -1, value: "please_select", disabled: true },
  { id: 0, value: "None" },
  { id: 1, value: "Camera" },
  { id: 2, value: "LinkBase IN", disabled: false },
];

export const ParkingLotOutputType: IOption[] = [
  { id: -1, value: "please_select", disabled: true },
  { id: 0, value: "None" },
  { id: 1, value: "LinkBase OUT", disabled: false },
];

export const parentFolderData: IOption[] = [
  { id: -1, value: "please_select", disabled: true },
  { id: 0, value: "Root Folder" },
  { id: 1, value: "Folder 1" },
  { id: 2, value: "Folder 2" },
  { id: 3, value: "Folder 3" },
];

export const slotTypeOptions: IOption[] = [
  { id: -1, value: "please_select", disabled: true },
  { id: 1, value: "TypeA" },
  { id: 2, value: "TypeB" },
  { id: 3, value: "TypeC" },
  { id: 4, value: "TypeD" },
];

export const slotCategoryOptions: IOption[] = [
  { id: 1, value: "Type A" },
  { id: 2, value: "Type B" },
  { id: 3, value: "Type C" },
  { id: 4, value: "Type D" },
  { id: 5, value: "Overall" },
];

export const slotStatusCategoryOptions: IOption[] = [
  { id: -1, value: "please_select", disabled: true },
  { id: 1, value: "TypeA" },
  { id: 2, value: "TypeB" },
  { id: 3, value: "TypeC" },
  { id: 4, value: "TypeD" },
  { id: 5, value: "Overall" },
];

export const parkingLotStatusOptions: IOption[] = [
  { id: -1, value: "please_select", disabled: true },
  { id: 0, value: "Available" },
  { id: 1, value: "Crowded" },
  { id: 2, value: "Full" },
  { id: 3, value: "Closed" },
];

export const AddparkingLotStatusOptions: IOption[] = [
  { id: -1, value: "please_select", disabled: true },
  { id: 0, value: "Available" },
  { id: 3, value: "Closed" },
];

export const parkingLotStatusOptionsArray: IOption[] = [
  { id: 0, value: "Available" },
  { id: 1, value: "Crowded" },
  { id: 2, value: "Full" },
  { id: 3, value: "Closed" },
];

export const specialScheduleTypeOptions: IOption[] = [
  { id: -1, value: "please_select", disabled: true },
  { id: 0, value: "official" },
  { id: 1, value: "special" }
]

export const slotEnableDisableOptions: IOption[] = [
  // { id: -1, value: "please_select", disabled: true },
  { id: 0, value: "Enable" },
  { id: 1, value: "Disable" },
  // { id: 2, value: "Closed" },
];

export const generateTypeOptions: IOption[] = [
  { id: -1, value: "please_select", disabled: true },
  { id: 0, value: "Yes" },
  { id: 1, value: "No" },
];

export const mediaFolderCategoryOptions: IOption[] = [
  { id: -1, value: "please_select", disabled: true },
  { id: 0, value: "parking_lot_status_images" },
  { id: 1, value: "price_images" },
  { id: 2, value: "car_slot_images" },
  { id: 3, value: "animation" },
  { id: 4, value: "fixed_video" },
  { id: 5, value: "fixed_image" },
]

export const weekdayOptions: IOption[] = [
  { id: -1, value: "please_select", disabled: true },
  { id: 0, value: "monday" },
  { id: 1, value: "tuesday" },
  { id: 2, value: "wednesday" },
  { id: 3, value: "thursday" },
  { id: 4, value: "friday" },
  { id: 5, value: "saturday" },
  { id: 6, value: "sunday" },
]

export const integratorDummyData: any[] = [
  { IntegratorId: "1", IntegratorName: "Integrator1", Comment: "N/A", isEdit: false },
  { IntegratorId: "2", IntegratorName: "Integrator2", Comment: "N/A", isEdit: false },
  { IntegratorId: "3", IntegratorName: "Integrator3", Comment: "N/A", isEdit: false }
]


