/*
<summary>
  This functional component "AddUser" defines the props like initial values and functions for the child form component.
  Developer:Aashish Singh, Created Date:29-August-2023
</summary>
<returns>Returns JSX</returns>
*/

import "./add-edit-system-user.css";
import { observer } from "mobx-react";
import React, { useEffect } from "react";
import AddUserForm from "../../forms/system-user/add-edit-system-user-form";
import toast from "react-hot-toast";
import { formatMessage } from "../../../../translations/format-message";
import { useStore } from "../../../../contexts/store-provider";
import { IAddKmsUser, IUpdateKmsUser } from "../../../../models/forms/IAddUser";
import { systemUserTypeOptions } from "../../../../constants/options.constant";
import accountStatusEnum from "../../../../constants/enums/account-status-type-enum";
import base64 from "base-64";
import { getTenantAccessFromLocalStorage, getUserId } from "../../../../helpers/localstorage-helper";

interface IProps {
  id: number;
  modalClosed: () => void;
}

const AddSystemUser = (props: IProps) => {
  const { userStore, tenantStore } = useStore();
  const { AddKmsUserService, UpdateUserService, GetUserByIdService, resetAddUpdateUserState, resetUserDetail, addUpdateUserState, systemUserDetail, userState } = userStore;
  const { GetAllTenants, allAvailableTenants, getAllTenantState } = tenantStore;
  const initialValues: any = systemUserDetail;

  /**
   * The useEffect Hook lets you perform side effects in function component.
   * The function we pass to the useEffect Hook runs when the component is mounted and when it is re-rendered
   * while a dependency of the useEffect has changed.
   */
  useEffect(() => {
    GetAllTenants();
    if (props.id > 0) {
      GetUserByIdService(props.id);
    }
  }, []);

  /**
   * The function we pass to the useEffect Hook runs when the component is mounted and when it is re-rendered while
   * dependency "addUpdateUserSuccess" of the useEffect changed.
   */
  useEffect(() => {
    if (addUpdateUserState.success) {
      if (props.id === -1)
        toast.success(formatMessage("added_success"));
      else toast.success(formatMessage("updated_success"));
      onModalClosed();
    }
  }, [addUpdateUserState.success]);

  /**
   * The function we pass to the useEffect Hook runs when the component is mounted and when it is re-rendered while
   * dependency "userDetailError" of the useEffect changed.
   */
  useEffect(() => {
    if (userState.error) {
      toast.error(formatMessage(userState.error));
    }
  }, [userState.error]);

  /**
   * The function we pass to the useEffect Hook runs when the component is mounted and when it is re-rendered while
   * dependency "addUpdateUserError" of the useEffect changed.
   */
  useEffect(() => {
    if (addUpdateUserState.error) {
      toast.error(formatMessage(addUpdateUserState.error));
      resetAddUpdateUserState();
    }
  }, [addUpdateUserState.error]);

  /**
   * This function adds the user when "Id < 0" and updates the Existing user when Id > 0 by providing form values to the
   * correponding function
   */
  const onSubmitForm = (values: any) => {
    if (props.id < 0) {
      var obj: IAddKmsUser = {
        TenantId: values.TenantId,
        Email: values.Email.trim(),
        Password: base64.encode(values.Password),
        UserName: values.UserName.trim(),
        UserType: Number(values.UserType),
        AccountStatus: accountStatusEnum.Pending,
        ConfirmPassword: base64.encode(values.ConfrimPassword)
        // PermissionType: 0
      }
      AddKmsUserService(obj);
    }
    else {
      var updateObj: IUpdateKmsUser = {
        Id: Number(props.id),
        Email: initialValues.Email.trim(),
        // Password: base64.encode(values.Password) || "",
        UserName: values.UserName.trim(),
        UserType: Number(values.UserType),
        AccountStatus: accountStatusEnum.Active,
        // ConfirmPassword: base64.encode(values.ConfrimPassword)
        // PermissionType: 0
      }
      UpdateUserService(props.id, updateObj);
    }
  };

  /**
   * This function closes the pop up modal and reset getuserdetails, addUpdate user observables (success, inprogress
   * and error).
   */
  const onModalClosed = () => {
    resetUserDetail();
    props.modalClosed();
  };


  return (
    <React.Fragment>
      <AddUserForm
        initialValues={initialValues}
        id={props.id}
        // userControlOptions={systemUserTypeOptions}
        userControlOptions={Number(getTenantAccessFromLocalStorage()) === -1 || props.id === Number(getUserId()) ? systemUserTypeOptions.filter(item => item.value !== 'CompanyUser') : systemUserTypeOptions.filter(item => item.value !== 'CompanyAdmin')}
        tenantOptions={allAvailableTenants}
        submitHandler={onSubmitForm}
        onModalClosed={onModalClosed}
        getUserLoading={userState.inProgress}
        addUpdateUserLoading={addUpdateUserState.inProgress || getAllTenantState.inProgress}
      />
    </React.Fragment>
  );
};

export default observer(AddSystemUser);
