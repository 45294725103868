/*
<summary>
   This file is used to provide initial values or state to the User Module observables.
    Developer:Mohammad Saquib Khan, Created Date:03-April-2024
</summary>
*/

import { IProductListVM, IWeightedProductListVM } from "../../models/response/IProductResponse";

export const initialState: IProductListVM = {
    PagingDetails: {
        TotalRecords: 1,
        TotalPages: 1,
        CurrentPage: 1,
        PageSize: 10,
    },
    Products: []
}

export const initialWeightedProductState: IWeightedProductListVM = {
    PagingDetails: {
        TotalRecords: 1,
        TotalPages: 1,
        CurrentPage: 1,
        PageSize: 10,
    },
    Products: []
}