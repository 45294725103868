import { ModuleType } from "../constants/enums/module-type-enum";
import { PermissionType } from "../constants/enums/permission-type-enum";
import userTypeEnum from "../constants/enums/user-type-enum";
import authStore from "../core/stores/auth-store";
import preferenceStore from "../core/stores/preferences-store";
import { IRoleVM } from "../models/response/IRoleResponse";
import RoutesConstants from "../shared-components/routes/routes-constants";
import { getIsTenantAccessFromLocalStorage, getTenantAccessFromLocalStorage, getTokenFromLocalStorage, getUserTypeFromLocalStorage } from "./localstorage-helper";

export const findRouteAccess = (module: number) => {
    let userPermission: any = authStore.getAllPermission;
    let userRole: any = authStore.getUserType;
    let isTenantAccess: any = getIsTenantAccessFromLocalStorage();
    if (userRole != userTypeEnum[4]) {
        if (userRole == userTypeEnum[0] || userRole == userTypeEnum[1]) {
            if (JSON.stringify(userPermission) === "[]") {
                const permissions = localStorage.getItem('kms_permissions');
                if (permissions)
                    userPermission = JSON.parse(permissions);
            }
            let modulePermission = userPermission.filter((per: IRoleVM) => per.ModuleType == module);
            if (modulePermission.length === 1)
                if (modulePermission[0].PermissionType == PermissionType.AllAccess || modulePermission[0].PermissionType == PermissionType.ViewOnly) {
                    return kmsManagementRouteSetup(module);
                }
                else {
                    return false;
                }
        }
        else {
            if (JSON.stringify(userPermission) === "[]") {
                const permissions = localStorage.getItem('kms_permissions');
                if (permissions)
                    userPermission = JSON.parse(permissions);
            }
            let modulePermission = userPermission.filter((per: IRoleVM) => per.ModuleType == module);
            if (modulePermission.length === 1)
                return (modulePermission[0].PermissionType == PermissionType.AllAccess || modulePermission[0].PermissionType == PermissionType.ViewOnly)
            return false;
        }
    }
    else {
        return (kmsManagementRouteSetup(module))
    }
}

export const redirectToDefaultRoute = () => {
    let roleType = authStore.userType ? authStore.userType : localStorage.getItem('kms_userType');
    let userType = getUserTypeFromLocalStorage();
    if (roleType)
        if (userType == userTypeEnum[4] || userType == userTypeEnum[0] || userType == userTypeEnum[1])
            if (getIsTenantAccessFromLocalStorage())
                return RoutesConstants.BranchManagement;
            else
                return RoutesConstants.TenantManagement;
        else if (userType == userTypeEnum[2] || userType == userTypeEnum[3])
            return RoutesConstants.Products;
        else return RoutesConstants.Login;
    else if (getTokenFromLocalStorage() === "")
        return RoutesConstants.Login;
    else
        return RoutesConstants.Login;
}

export const kmsManagementRouteSetup = (module: number) => {
    let isPriceSchedulerActive = preferenceStore.getPriceSchedulerAccess.isAccessPriceSchedulerActive;
    let isTenantAccess: any = getIsTenantAccessFromLocalStorage();
    if (ModuleType.BranchManagement == module || ModuleType.ParkingManagementList == module || ModuleType.MediaManagement == module) {
        return (isTenantAccess);
    }
    else if (ModuleType.PriceSchedular == module) {
        return (isTenantAccess && isPriceSchedulerActive)
    }
    else if ((ModuleType.KmsUserManagement == module || ModuleType.CompanyUserManagement == module || ModuleType.TenantManagement == module) && isTenantAccess == true) {
        return false;
    }
    else {
        return true;
    }
}