/*
<summary>
  This functional component is used for proper authentication of user using the Login Credentials (Username & Password)
  Developer:Aashish Singh, Created Date:28-Aug-2022, Last Updated By: Mohammad Saquib Khan, Updated Date: 26-Oct-2022
</summary>
<returns>Returns JSX</returns>
*/

import React, { useEffect } from "react";
import { toast } from "react-toastify";
import "./login.css";
import { formatMessage } from "../../translations/format-message";
import { observer } from "mobx-react";
import { useStore } from "../../contexts/store-provider";
import { useCookies } from "react-cookie";
import ILogin from "../../models/forms/ILogin";
import RoutesConstants from "../../shared-components/routes/routes-constants";
import LoginForm from "./forms/login-form";
import Footer from "../../shared-components/footer/footer";
import { errorMessage } from "../../constants/error-constants";

const css = `
body, html {
  overflow: visible;
  height: auto;
background: #252027;
}
`;
const Login = (props: any) => {
  const { authStore, preferencesStore } = useStore();

  const { error, inProgress, getUserType }: any = authStore;
  const { getVersion } = preferencesStore;
  const {
    isAuthenticated,
    getToken,
    login,
  } = authStore;
  const [cookies, setCookie, removeCookie] = useCookies(["user"]);
  const emailFromCookies = cookies.user;
  const initialValues: ILogin = {
    email: emailFromCookies !== undefined ? emailFromCookies : "",
    password: "",
    rememberMe: emailFromCookies !== undefined ? true : false,
  };

  /**
   * This function handles login functionlity for user
   */
  const handleLogin = (values: ILogin) => {
    if (values.rememberMe == true) {
      setCookie("user", values.email);
    } else {
      if (cookies.user != "") {
        removeCookie("user");
      }
    }
    login(values);
  };

  /**
   * The function we pass to the useEffect Hook runs when the component is mounted and when it is re-rendered while
   * dependency "isAuthenticated" of the useEffect changed.
   */
  useEffect(() => {
    if (isAuthenticated || getToken) {
      getVersion();
      if (getUserType === "SuperAdministrator") {
        props.history.push(RoutesConstants.KmsUser);
      }
      else {
        props.history.push(RoutesConstants.Products);
      }
    }
  }, [isAuthenticated]);

  /**
   * The function we pass to the useEffect Hook runs when the component is mounted and when it is re-rendered while
   * dependency "error" of the useEffect changed.
   */
  useEffect(() => {
    if (error) {
      if (error === errorMessage.UserAlreadyLogin) {
        props.confirmLoginPopupToggleHandler()
      }
      else {
        toast.error(formatMessage(error));
        authStore.reset();
      }
    }
  }, [error]);

  return (
    <>
      <div className="login-outer background bg-white">
        <LoginForm
          isLoading={inProgress}
          loginHandler={handleLogin}
          initialValues={initialValues}
        />
        <style>{css}</style>
      </div>
      <Footer />
    </>
  );
};

export default (observer(Login));
