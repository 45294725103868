/*
<summary>
   This typescript file contains special datatype that enables for a variale to be set of predefined enums.
   This enum is used for the pagination & dashboard setting for corresponding modules.
   Developer: Raman Sharma, Created Date: 16-August-2024
</summary>
*/
enum productSettingEnum {
    ProductLimitConfig = 'productLimitConfig',
}

export default productSettingEnum;