/*
<summary>
  This functional component "UserProfile" provides user setting functionality like changing language with the help "setting" tab props like initial values.
  Developer:Aashish Singh, Created Date:28-Aug-2022, Last Updated By: Mohammad Saquib Khan, Updated Date: 13-Dec-2022
</summary>
<returns>Returns JSX</returns>
*/
import { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import "./user-profile.css";
import { useStore } from "../../../contexts/store-provider";
import Label from "../../../shared-components/label/label";
import { Formik, Form, Field } from "formik";
import { Form as BsForm } from 'react-bootstrap';
import FormikFormInput from "../../../shared-components/FormikFormHandlers/FormikFormInput";
import { UpdatePasswordValidateSchema } from "../../../shared-components/FormikFormHandlers/FormikValidationSchema";
import LoaderButton from "../../../shared-components/button/loader-button";
import base64 from "base-64";
import { observer } from "mobx-react";
import validCharacterPassword from "../../../constants/valid-character-password";
interface IProps {
  userName: string;
  userType: string;
  onModalClosed: () => void;
}

const UserProfile: React.FC<IProps> = (props) => {
  const { authStore, userStore } = useStore();
  const { getUserType, getEmail, getUserId } = authStore;
  const [showHidePassword, setShowHidePassword] = useState(false);
  const [showHideConfirmPassword, setShowHideConfirmPassword] = useState(false);
  const [showHideChangePassword, setShowHideChangePassword] = useState(false);
  const {
    UpdateUserPasswordService,
    addUpdateUserState,
    changePasswordState,
    resetChangePassword,
    inProgress,
    resetAddUpdateUserState,
  } = userStore;

  useEffect(() => {
    if (changePasswordState.error) {
      resetChangePassword();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [changePasswordState.error]);

  useEffect(() => {
    if (changePasswordState.success) {
      props.onModalClosed();
      resetChangePassword();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [changePasswordState.success]);

  return (
    <Modal
      className="add-profile-modal"
      show={true}
      centered
      onHide={props.onModalClosed}
    >
      <Modal.Header className="header-bg"
        data-testid="closebtn"
        onClick={props.onModalClosed}
        closeButton
      >
        {/* <Modal.Title>
          <FormattedMessage id="profile" />
        </Modal.Title> */}
      </Modal.Header>
      <Modal.Body className="p-5 pt-0">
        <div className="row">
          <div className="col-12">
            <div className="profile_modal_icon">
              <svg
                version="1.1"
                xmlns="http://www.w3.org/2000/svg"
                x="0px"
                y="0px"
                viewBox="0 0 45.532 45.532"
              >
                <g>
                  <path
                    d="M22.766,0.001C10.194,0.001,0,10.193,0,22.766s10.193,22.765,22.766,22.765c12.574,0,22.766-10.192,22.766-22.765
                    S35.34,0.001,22.766,0.001z M22.766,6.808c4.16,0,7.531,3.372,7.531,7.53c0,4.159-3.371,7.53-7.531,7.53
                    c-4.158,0-7.529-3.371-7.529-7.53C15.237,10.18,18.608,6.808,22.766,6.808z M22.761,39.579c-4.149,0-7.949-1.511-10.88-4.012
                    c-0.714-0.609-1.126-1.502-1.126-2.439c0-4.217,3.413-7.592,7.631-7.592h8.762c4.219,0,7.619,3.375,7.619,7.592
                    c0,0.938-0.41,1.829-1.125,2.438C30.712,38.068,26.911,39.579,22.761,39.579z"
                  />
                </g>
                <g></g>
                <g></g>
                <g></g>
                <g></g>
                <g></g>
                <g></g>
                <g></g>
                <g></g>
                <g></g>
                <g></g>
                <g></g>
                <g></g>
                <g></g>
                <g></g>
                <g></g>
              </svg>
            </div>
          </div>
          <div className="col-12 text-center mt-1">
            <div className="bootbox-body user-details">
              <h3 className="m-0">{getEmail ? getEmail : ""}</h3>
              <small className="text-muted">
                {getUserType ? getUserType : ""}
              </small>
            </div>
          </div>
        </div>
        <div className="col-md-12 col-12 mt-3">
          <div className="form-group change-password">
            <BsForm.Check
              type="switch"
              id="custom-switch"
              label="Change Password?"
              onClick={() => setShowHideChangePassword(!showHideChangePassword)}
            />
          </div>
        </div>
        <div className="reset-password"></div>
        {showHideChangePassword &&
          <Formik
            initialValues={{ OldPassword: "", NewPassword: "" }}
            enableReinitialize
            validationSchema={UpdatePasswordValidateSchema}
            validateOnBlur={false}
            onSubmit={async (values, { resetForm }) => {
              UpdateUserPasswordService(
                base64.encode(values.OldPassword),
                base64.encode(values.NewPassword),
                getUserId
              );
            }}
          >
            {({ dirty }) => {
              return (
                <Form className="mt-3 change-password">
                  <div className="form-group mb-3">
                    <Label required={true} label="old_password" />
                    <Field
                      data-testid="testPassword"
                      className="form-control"
                      name="OldPassword"
                      autoComplete="new-password"
                      type={showHidePassword ? "text" : "password"}
                      invalidcharacter={[" "]}
                      validcharacter={validCharacterPassword}
                      placeholder=""
                      as={FormikFormInput}
                    />
                    {showHidePassword ? (
                      <svg
                        type="button"
                        onClick={() => {
                          setShowHidePassword(!showHidePassword);
                        }}
                        viewBox="0 0 24 24"
                        width="20"
                        height="20"
                        stroke="currentColor"
                        strokeWidth="2"
                        fill="none"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        className="showHidePass css-i6dzq1 modalBox"
                      >
                        <path d="M1 12s4-8 11-8 11 8 11 8-4 8-11 8-11-8-11-8z"></path>
                        <circle cx="12" cy="12" r="3"></circle>
                      </svg>
                    ) : (
                      <svg
                        type="button"
                        onClick={() => {
                          setShowHidePassword(!showHidePassword);
                        }}
                        viewBox="0 0 24 24"
                        width="20"
                        height="20"
                        stroke="currentColor"
                        strokeWidth="2"
                        fill="none"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        className="showHidePass css-i6dzq1 modalBox"
                      >
                        <path d="M17.94 17.94A10.07 10.07 0 0 1 12 20c-7 0-11-8-11-8a18.45 18.45 0 0 1 5.06-5.94M9.9 4.24A9.12 9.12 0 0 1 12 4c7 0 11 8 11 8a18.5 18.5 0 0 1-2.16 3.19m-6.72-1.07a3 3 0 1 1-4.24-4.24"></path>
                        <line x1="1" y1="1" x2="23" y2="23"></line>
                      </svg>
                    )}
                  </div>
                  <div className="form-group mb-3">
                    <Label
                      className="label-style"
                      required={true}
                      label="new_password"
                    />
                    <div>
                      <Field
                        data-testid="testConfirmPassword"
                        className={`form-control ${showHidePassword === showHideConfirmPassword ? "" : ""
                          }`}
                        // className={`form-control ${showHidePassword === showHideConfirmPassword ? 'border border-success-subtle' : ''}`}
                        name="NewPassword"
                        autoComplete="confirm-new-password"
                        type={showHideConfirmPassword ? "text" : "password"}
                        invalidcharacter={[" "]}
                        validcharacter={validCharacterPassword}
                        placeholder=""
                        as={FormikFormInput}
                      />
                      {showHideConfirmPassword ? (
                        <svg
                          type="button"
                          onClick={() => {
                            setShowHideConfirmPassword(!showHideConfirmPassword);
                          }}
                          viewBox="0 0 24 24"
                          width="20"
                          height="20"
                          stroke="currentColor"
                          strokeWidth="2"
                          fill="none"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          className="showHidePass css-i6dzq1 modalBox"
                        >
                          <path d="M1 12s4-8 11-8 11 8 11 8-4 8-11 8-11-8-11-8z"></path>
                          <circle cx="12" cy="12" r="3"></circle>
                        </svg>
                      ) : (
                        <svg
                          type="button"
                          onClick={() => {
                            setShowHideConfirmPassword(!showHideConfirmPassword);
                          }}
                          viewBox="0 0 24 24"
                          width="20"
                          height="20"
                          stroke="currentColor"
                          strokeWidth="2"
                          fill="none"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          className="showHidePass css-i6dzq1 modalBox"
                        >
                          <path d="M17.94 17.94A10.07 10.07 0 0 1 12 20c-7 0-11-8-11-8a18.45 18.45 0 0 1 5.06-5.94M9.9 4.24A9.12 9.12 0 0 1 12 4c7 0 11 8 11 8a18.5 18.5 0 0 1-2.16 3.19m-6.72-1.07a3 3 0 1 1-4.24-4.24"></path>
                          <line x1="1" y1="1" x2="23" y2="23"></line>
                        </svg>
                      )}
                    </div>
                  </div>
                  <LoaderButton
                    type="submit"
                    className="w-100 mt-3 btn btn-primary"
                    isLoading={changePasswordState.inProgress}
                    text="save"
                  />
                </Form>
              );
            }}
          </Formik>
        }
        {/* <Button variant="primary" type="submit" className="w-100 mt-3">
          <FormattedMessage id="update_password" />
        </Button> */}
      </Modal.Body>
    </Modal>
  );
};

export default observer(UserProfile);
