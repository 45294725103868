/*
<summary>
   This file is used to provide initial values or state to the Product Data observables.
     Developer:Raman Sharma, Created Date:16-August-2024
</summary>
*/

import { IAddProduct } from "../../models/forms/IAddProduct";


export const initialState: IAddProduct = {
  Description: "",
  ProductCode: "",
  Sku: "",
  Name: "",
  Variety: "",
  TenantId: -1,
}

export const initialStateWeightedProduct = {
  Weight: -1,
  Sku: "",
  Name: "",
  Image: "",
  Id: -1,
  AddedDate: ""
}