/*
<summary>
  This functional component provides "ChangePasswordPopupHOC" props to the child component.
  1. State "showChangePasswordPopup" that manages the show/hide of pop up
  2. Function "changePasswordToggleHandler" to toggle the show/hide popup state "showChangePasswordPopup"
  Developer:Aashish Singh, Created Date:29-August-2023
</summary>
<returns>Returns JSX</returns>
*/

import { useState } from "react";

const ChangePasswordPopupHOC = (Component: any) =>
  function Comp(props: any) {
    const [showChangePasswordPopup, setShowChangePasswordPopup] =
      useState<boolean>(false);

    const ChangePasswordToggleHandler = () => {
      setShowChangePasswordPopup(!showChangePasswordPopup);
    };

    return (
      <Component
        showChangePasswordPopup={showChangePasswordPopup}
        changePasswordToggleHandler={ChangePasswordToggleHandler}
        {...props}
      />
    );
  };

export default ChangePasswordPopupHOC;
