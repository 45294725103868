/*
<summary>
   This typescript file contains special datatype that enables for a variale to be set of predefined enums.
   This enum is used to differentiate user types in the overall application.
   Developer: Mohammad Saquib Khan, Created Date: 29-March-2023
</summary>
*/
enum accountStatusEnum {
    Pending,
    Active
}

export default accountStatusEnum