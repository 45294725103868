/*
<summary>
   This file is used to provide initial values or state to the User Logs Module observables.
   Developer: Mohammad Saquib Khan, Created Date: 12-June-2024
</summary>
*/

import { ISystemLogsVM } from "../../models/response/ISystemLogsResponse";
import { IUserLogsVM } from "../../models/response/IUserLogsResponse";

export const initialState: ISystemLogsVM = {
    PagingDetails: {
        TotalRecords: 1,
        TotalPages: 1,
        CurrentPage: 1,
        PageSize: 10,
    },
    Logs: []
}