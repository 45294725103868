/*
<summary>
    This functional common component provides modal popup that shows image.
    Developer:Raman Sharma, Created Date:04-Sep-2024
</summary>
<returns>Returns JSX</returns>
*/
import React from "react";
import { Modal, Button } from "react-bootstrap";
import { FormattedMessage } from "react-intl";
import LoaderButton from "../../button/loader-button";
import "./image-popup.css";
interface Props {
  modalClosed: () => void;
  isLoading: boolean;
  image: string;
}

const ImagePopup: React.FC<Props> = (props) => {
  return (
    <Modal
      show={true}
      centered
      dialogClassName="view-product-modal"
      onHide={() => { }}
    >
 
      <Modal.Body className="p-0">
      <Button
          onClick={props.modalClosed}
          className="Crossicons"
        >
            <svg
                xmlns="http://www.w3.org/2000/svg"
                width="23"
                height="23"
                fill="currentColor"
                className="bi bi-x"
                viewBox="0 0 16 16"
              >
                <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
              </svg>
       
   </Button>
        <div className="product-images-view">  <img src={`data:image/jpeg;base64,${props.image}`} alt="Product Image" className="productImages img-fluid" />
        </div>
      </Modal.Body>
     
       
    </Modal>
  );
};

export default ImagePopup;
