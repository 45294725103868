/*
<summary>
   This functional common component provides us custom input checkbox & has error handler that shows validation error.
   Developer:Aashish Singh, Created Date:08-Mar-2023
</summary>
<returns>Returns JSX</returns>
*/
import React from 'react';
import { injectIntl } from 'react-intl';
import FormikFormErrorHandler from './FormikFormErrorHandler';
import { useField } from 'formik';

interface IProps {
  children: React.ReactNode;
  className: string;
  "data-testid"?: string;
  intl: any;//IntlConfig;
  name: string;
  onBlur: () => void;
  onChange: () => void;
  placeholder: string;
  type: string;
  value: string;
  disabled: boolean;
  readOnly: boolean;
  spaceAllowed: boolean;
}

const FormikFormCheckBox = (props: IProps) => {
  const [field, meta, helpers] = useField(props);
  const errorClass = `${(meta.error && meta.touched) ? 'error' : ''}`;
  const _placeholder = props.placeholder
    ? props.intl.formatMessage({ id: props.placeholder })
    : props.placeholder;

  return (
    <>
      <input {...field} {...props}
        placeholder={_placeholder}
        className={props.className + ' ' + errorClass}
        disabled={props.disabled}
        type={props.type}
        readOnly={props.readOnly}
        onChange={event => helpers.setValue(event.target.checked)}
      />
      <FormikFormErrorHandler intl={props.intl} meta={meta} />
    </>
  );
};

export default injectIntl(FormikFormCheckBox);