/*
<summary>
   This functional common component provides us table view of data with buttons like edit and delete etc.
   Developer:Aashish Singh, Created Date:25-Aug-2022
</summary>
<returns>Returns JSX</returns>
*/
import { observer } from "mobx-react";
import React, { useEffect } from "react";
import BootstrapTable, { RowEventHandlerProps } from 'react-bootstrap-table-next';
import FormLoader from "../FormLoader/FormLoader";
import "./Grid.css";
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';

interface IProps {
    keyField: string;
    data: any;
    columns: any;
    loading?: boolean;
    selectRow?: any;
    rowClasses?: (row: any, rowIdx: number) => string;
    rowStyle?: Object;
    rowEvents?: RowEventHandlerProps | undefined;
    onTableChange?: any;
    sort?: any
}
const Grid: React.FC<IProps> = (props) => {
    const { keyField, data, columns, rowClasses, rowStyle, rowEvents, loading, selectRow, sort } = props;
    return (
        <React.Fragment>
            <BootstrapTable
                // remote
                bootstrap4
                keyField={keyField}
                data={data}
                columns={columns}
                selectRow={selectRow}
                rowClasses={rowClasses ? rowClasses : undefined}
                sort={sort}
                // onTableChange={props.onTableChange} //This callback function will be called when remote enabled only.
                rowStyle={rowStyle ? rowStyle : undefined}
                rowEvents={rowEvents ? rowEvents : undefined}
            />
            {(data.length === 0 && loading === true) &&
                <FormLoader loading={true} />
            }
            {/* {props.children} */}
        </React.Fragment>
    );
}

export default observer(Grid);


