/*
<summary>
  This functional component "AddProduct" defines the props like initial values and functions for the child form component.
  Developer:Raman Sharma, Created Date:16-August-2024
</summary>
<returns>Returns JSX</returns>
*/

import "./add-edit-product.css";
import { observer } from "mobx-react";
import React, { useEffect } from "react";
import toast from "react-hot-toast";
import { formatMessage } from "../../../translations/format-message";
import { useStore } from "../../../contexts/store-provider";
import { IAddProduct, IUpdateProduct } from "../../../models/forms/IAddProduct";
import AddProductForm from "../forms/add-edit-product-form";
import {
  getTenantIdFromLocalStorage,
  getUserId,
} from "../../../helpers/localstorage-helper";

interface IProps {
  id: number;
  modalClosed: () => void;
}

const AddProduct = (props: IProps) => {
  const { productStore, preferencesStore } = useStore();
  const {
    AddProductService,
    UpdateProductService,
    GetProductByIdService,
    resetAddUpdateProductState,
    resetProductDetail,
    addUpdateProductState,
    productDetail,
    selectedTenantId,
  } = productStore;
  const initialValues: any = productDetail;

  const { tenantAccess } = preferencesStore;

  /**
   * The useEffect Hook lets you perform side effects in function component.
   * The function we pass to the useEffect Hook runs when the component is mounted and when it is re-rendered
   * while a dependency of the useEffect has changed.
   */
  useEffect(() => {
    if (props.id > 0) {
      GetProductByIdService(
        props.id,
        getTenantIdFromLocalStorage() === "-1"
          ? selectedTenantId
          : Number(getTenantIdFromLocalStorage())
      );
    }
  }, []);

  /**
   * The function we pass to the useEffect Hook runs when the component is mounted and when it is re-rendered while
   * dependency "addUpdateProductSuccess" of the useEffect changed.
   */
  useEffect(() => {
    if (addUpdateProductState.success) {
      if (props.id === -1) toast.success(formatMessage("added_success"));
      else toast.success(formatMessage("updated_success"));
      onModalClosed();
    }
  }, [addUpdateProductState.success]);

  /**
   * The function we pass to the useEffect Hook runs when the component is mounted and when it is re-rendered while
   * dependency "productDetailError" of the useEffect changed.
   */
  // useEffect(() => {
  //   if (addUpdateProductState.error) {
  //     toast.error(formatMessage(addUpdateProductState.error));
  //   }
  // }, [addUpdateProductState.error]);

  /**
   * The function we pass to the useEffect Hook runs when the component is mounted and when it is re-rendered while
   * dependency "addUpdateProductError" of the useEffect changed.
   */
  useEffect(() => {
    if (addUpdateProductState.error) {
      toast.error(formatMessage(addUpdateProductState.error));
      resetAddUpdateProductState();
    }
  }, [addUpdateProductState.error]);

  /**
   * This function adds the product when "Id < 0" and updates the Existing product when Id > 0 by providing form values to the
   * correponding function
   */
  const onSubmitForm = (values: any) => {
    var addObj: IAddProduct = {
      Description: values.Description.trim(),
      ProductCode: values.ProductCode.trim(),
      UserId: JSON.parse(getUserId()!),
      Sku: values.Sku.trim(),
      Name: values.Name.trim(),
      Variety: values.Variety.trim(),
      TenantId: Number(getTenantIdFromLocalStorage()),
    };

    var updateObj: IUpdateProduct = {
      Description: values.Description.trim(),
      ProductCode: values.ProductCode.trim(),
      UserId: tenantAccess.tenantId,
      Sku: values.Sku.trim(),
      Name: values.Name.trim(),
      Variety: values.Variety.trim(),
      TenantId:
        getTenantIdFromLocalStorage() === "-1"
          ? selectedTenantId
          : Number(getTenantIdFromLocalStorage()),
      Id: props.id,
    };

    if (props.id < 0) AddProductService(addObj);
    else UpdateProductService(props.id, updateObj);
  };

  /**
   * This function closes the pop up modal and reset getproductdetails, addUpdate product observables (success, inprogress
   * and error).
   */
  const onModalClosed = () => {
    resetProductDetail();
    props.modalClosed();
  };

  return (
    <React.Fragment>
      <AddProductForm
        initialValues={initialValues}
        id={props.id}
        submitHandler={onSubmitForm}
        onModalClosed={onModalClosed}
        getUserLoading={addUpdateProductState.inProgress}
        addUpdateUserLoading={addUpdateProductState.inProgress}
      />
    </React.Fragment>
  );
};

export default observer(AddProduct);
