/*
<summary>
   This functional component provides us functionalities like redirection to other modules, logout & User Profile.
   Developer:Aashish Singh, Created Date:29-March-2023
</summary>
<returns>Returns JSX</returns>
*/
import React, { useEffect, useRef } from "react";
import "./left-navbar.css";
import { NavLink, useHistory } from "react-router-dom";
import { FormattedMessage, injectIntl } from "react-intl";
import { formatMessage } from "../../translations/format-message";
import { observer } from "mobx-react";
import { useStore } from "../../contexts/store-provider";
import UserProfilePopup from "../../modules/auth/user-profile-popup";
import LogoutPopup from "../../modules/auth/components/logout-popup";
import UserProfilePopupHOC from "../../modules/auth/hoc/user-profile-popup-hoc";
import LogoutPopupHOC from "../../modules/auth/hoc/logout-popup-hoc";
import RoutesConstants from "../routes/routes-constants";
import userTypeEnum from "../../constants/enums/user-type-enum";
import { getIsTenantAccessFromLocalStorage } from "../../helpers/localstorage-helper";
import { findRouteAccess } from "../../helpers/route-helper";
import { ModuleType } from "../../constants/enums/module-type-enum";
import { Dropdown } from "react-bootstrap";

interface IProps {
  changeLanguage: (lang: string) => void;
}

const LeftNavBar = (props: any) => {
  /* eslint-disable jsx-a11y/anchor-is-valid */
  let t: NodeJS.Timeout;
  const history = useHistory();
  const { preferencesStore, authStore } = useStore();
  const myRef = useRef<any>();
  const { getUserType, userType, getEmail } = authStore;
  const {
    getTenantAccess,
    setTenantAccess,
    resetMediaAccess,
    resetPriceSchedulerAccess,
    resetUnwantedModules,
  } = preferencesStore;
  const isKmsUser =
    (getUserType === userTypeEnum[0] ||
      getUserType === userTypeEnum[1] ||
      getUserType === userTypeEnum[4]) &&
    getIsTenantAccessFromLocalStorage() == false;
  const isCompany =
    (getUserType === userTypeEnum[2] || getUserType === userTypeEnum[3]) &&
    getIsTenantAccessFromLocalStorage() == false;

  const handleChange = () => {
    const div = document.getElementById("left-sidebarNav");
    const move = document.getElementById("moveContent");
    if (div) {
      if (!div.className.includes("closeNav")) {
        div.classList.add("closeNav");
        if (move) move.classList.remove("ShiftLeftSide");
      } else {
        div.classList.remove("closeNav");
        if (move) move.classList.add("ShiftLeftSide");
      }
    }
  };

  const handleChange2 = () => {
    const div = document.getElementById("left-sidebarNav");
    const move = document.getElementById("moveContent");
    if (div !== null) {
      div.classList.add("closeNav");
      if (move) move.classList.remove("ShiftLeftSide");
    }
  };

  // const closeOnOutsideClick = () => {
  //   var div = document.getElementById("left-sidebarNav");
  //   const move = document.getElementById("moveContent");
  //   if (div) {
  //     if (!div.className.includes("closeNav")) {
  //       div.classList.add("closeNav");
  //       if (move) move.classList.remove("ShiftLeftSide");
  //     }
  //   }
  // };

  const handleClickOutside = (e: any) => {
    if (!myRef.current.contains(e.target)) {
      // closeOnOutsideClick();
    }
  };

  const resetExtraModules = () => {
    resetUnwantedModules();
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const exitTenant = () => {
    setTenantAccess({
      isAccessTenantActive: false,
      tenantId: 0,
      tenantName: "",
      orgId: "",
    });
    resetMediaAccess();
    resetPriceSchedulerAccess();
    // closeOnOutsideClick();
    const div = document.getElementById("exit");
    const move = document.getElementById("moveContent");
    if (div) {
      if (!div.className.includes("closeNav")) {
        div.classList.add("closeNav");
        if (move) move.classList.remove("ShiftLeftSide");
      } else {
        div.classList.remove("closeNav");
        if (move) move.classList.add("ShiftLeftSide");
      }
    }
    history.push(RoutesConstants.TenantManagement);
  };

  const exitTenantHandler = () => {
    resetMediaAccess();
    resetPriceSchedulerAccess();
    // closeOnOutsideClick();
    history.push(RoutesConstants.TenantManagement);
  };
  return (
    <React.Fragment>
      {props.showUserProfilePopup && (
        <UserProfilePopup
          userName={props.email}
          userType={props.userType}
          modalClosed={props.UserProfileToggleHandler}
        />
      )}
      {props.showLogoutPopup && (
        <LogoutPopup modalClosed={props.logoutPopupToggleHandler} />
      )}

      <aside
        id="left-sidebarNav"
        ref={myRef}
        className={` ${getIsTenantAccessFromLocalStorage() == true &&
          getUserType === userTypeEnum[4]
          ? "primary-navbar"
          : ""
          } closeNav`}
      >
        <button
          id="nav-toogle"
          className="menu-toggle"
          onClick={handleChange}
          title={formatMessage("menu")}
        >
          <span></span>
          <span></span>
          <span></span>
        </button>
        <div className="nav-inner">
          <nav>
            <div className="sidebar-header">
              <div
                className="sidebar-logo"
                title={formatMessage("application_title")}
              >
                <svg
                  version="1.1"
                  id="Layer_1"
                  fill="#0094D9"
                  xmlns="http://www.w3.org/2000/svg"
                  x="0px"
                  y="0px"
                  viewBox="0 0 400 150"
                >
                  <g>
                    <g>
                      <path
                        d="M80.9,83.5c5.7-6.9,8.5-15.2,8.5-24.9c0-6.3-1.1-12.4-3.3-18.4c-1.4-3.9-3.2-7-5.4-9.4
			c-2.2-2.4-5.1-4.5-8.7-6.1c-5.7-2.6-12.3-3.9-20-3.9c-11.6,0-20.5,2.6-26.6,7.9C18.2,34.7,14.6,45,14.6,59.4
			c0,8.2,1.5,15.3,4.5,21.2c4,7.7,10.2,12.6,18.7,14.5c3.3,0.7,6.1,1.2,8.4,1.4c2.4,0.2,6.7,0.3,13,0.3l6.2,0.1h28.4V83.5H80.9z
			 M68.2,76.2c-3,5.4-8.3,8.1-16,8.1c-8.2,0-13.7-2.9-16.6-8.8C33.5,71,32.4,65.2,32.4,58c0-8.3,1.6-14.5,4.8-18.5
			c3.2-4,8.2-6.1,14.8-6.1c7.1,0,12.2,2.5,15.4,7.5c2.8,4.5,4.2,10.2,4.2,17.3C71.6,66.1,70.5,72.1,68.2,76.2z"
                      />
                    </g>
                  </g>
                </svg>
              </div>
            </div>
            <ul className="list-unstyled">
              {isKmsUser && (
                <React.Fragment>
                  {findRouteAccess(ModuleType.TenantManagement) && (
                    <li title={formatMessage("tenant_title")}>
                      <NavLink to={RoutesConstants.TenantManagement}>
                        <span className="svgWidth">
                          <svg
                            id="Layer_1"
                            enableBackground="new 0 0 512 512"
                            fill="rgb(134, 137, 144)"
                            height="20"
                            width="20"
                            viewBox="0 0 512 512"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <g id="home">
                              <g>
                                <path
                                  d="m400.959 499.81h-289.918c-32.868 0-59.607-26.74-59.607-59.607v-192.976c0-6.903 5.596-12.5 12.5-12.5 6.903 0 12.5 5.597 12.5 12.5v192.976c0 19.083 15.525 34.607 34.607 34.607h289.918c19.083 0 34.607-15.524 34.607-34.607v-192.976c0-6.903 5.597-12.5 12.5-12.5s12.5 5.597 12.5 12.5v192.976c0 32.866-26.74 59.607-59.607 59.607z"
                                  fill="rgb(0,0,0)"
                                />
                              </g>
                              <g>
                                <path
                                  d="m322.068 444.827c-6.903 0-12.5-5.597-12.5-12.5v-115.916c0-3.314-2.696-6.011-6.011-6.011h-96.055c-3.314 0-6.011 2.696-6.011 6.011v115.916c0 6.903-5.596 12.5-12.5 12.5s-12.5-5.597-12.5-12.5v-115.916c0-17.1 13.911-31.011 31.011-31.011h96.055c17.1 0 31.011 13.911 31.011 31.011v115.916c0 6.903-5.596 12.5-12.5 12.5z"
                                  fill="rgb(0,0,0)"
                                />
                              </g>
                              <g>
                                <path
                                  d="m15.455 229.727c-3.666 0-7.298-1.605-9.767-4.689-4.314-5.39-3.442-13.256 1.948-17.57l240.553-192.536c5.39-4.314 13.256-3.442 17.57 1.948s3.442 13.256-1.948 17.57l-240.553 192.535c-2.305 1.846-5.063 2.742-7.803 2.742z"
                                  fill="rgb(0,0,0)"
                                />
                              </g>
                              <g>
                                <path
                                  d="m496.545 229.727c-2.74 0-5.498-.896-7.803-2.742l-240.553-192.536c-5.39-4.313-6.262-12.18-1.948-17.57s12.183-6.262 17.569-1.948l240.553 192.536c5.391 4.313 6.263 12.18 1.948 17.57-2.468 3.085-6.101 4.69-9.766 4.69z"
                                  fill="rgb(0,0,0)"
                                />
                              </g>
                            </g>
                            <g />
                            <g />
                            <g />
                            <g />
                            <g />
                            <g />
                          </svg>
                        </span>
                        <span className="nav-text">
                          {<FormattedMessage id="tenant_title" />}
                        </span>
                      </NavLink>
                    </li>
                  )}
                </React.Fragment>
              )}


              {/* Only for testing purpose remove this later */}
              <li title={formatMessage("view_products")}>
                <NavLink to={RoutesConstants.Products}>
                  <span className="svgWidth">
                    <svg
                      fill="rgb(134, 137, 144)"
                      height="24"
                      width="24"
                      viewBox="0 0 24 24"

                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <g clipRule="evenodd" fillRule="evenodd">
                        <path d="m3.00003 8c0-.55228.44771-1 1-1h15.99997c.5523 0 1 .44772 1 1v10c0 1.6569-1.3431 3-3 3h-11.99997c-1.65686 0-3-1.3431-3-3zm2 1v9c0 .5523.44771 1 1 1h11.99997c.5523 0 1-.4477 1-1v-9z" />
                        <path d="m7.29292 3.29289c.18754-.18753.44189-.29289.70711-.29289h7.99997c.2652 0 .5196.10536.7071.29289l4 4c.286.286.3716.71612.2168 1.08979-.1548.37368-.5194.61732-.9239.61732h-15.99997c-.40446 0-.7691-.24364-.92388-.61732-.15478-.37367-.06923-.80379.21677-1.08979zm1.12132 1.70711-2 2h11.17156l-2-2z" />
                        <path d="m7.00003 12c0-.5523.44771-1 1-1h3.99997c.5523 0 1 .4477 1 1s-.4477 1-1 1h-3.99997c-.55229 0-1-.4477-1-1z" />
                      </g>
                    </svg>
                  </span>
                  <span className="nav-text">
                    {<FormattedMessage id="view_products" />}
                  </span>
                </NavLink>
              </li>

              {findRouteAccess(ModuleType.ProductManagement) && (
                <li title={formatMessage("weigheted_products")}>
                  <NavLink to={RoutesConstants.WeightedProducts}>
                    <span className="svgWidth">
                      <svg
                        fill="rgb(134, 137, 144)"
                        height="24"
                        width="24"
                        viewBox="0 0 512 512"
                        xmlns="http://www.w3.org/2000/svg"
                      >

                        <g clipRule="evenodd" fillRule="evenodd">
                          <path d="M400.987,90.342c30.772-16.21,55.752-39.487,72.238-67.314c2.793-4.714,2.842-10.565,0.129-15.326
			C470.641,2.94,465.582,0,460.101,0H51.898c-5.48,0-10.539,2.94-13.252,7.701c-2.713,4.762-2.664,10.613,0.129,15.326
			c16.486,27.827,41.466,51.105,72.238,67.314c26.424,13.92,56.216,22.137,86.911,24.073V153.6h-72.087
			c-26.953,0-49.748,20.174-53.024,46.926L42.009,452.087c-1.859,15.176,2.888,30.446,13.021,41.896
			C65.163,505.433,79.744,512,95.033,512h321.942c15.289,0,29.87-6.567,40.003-18.016c10.133-11.45,14.88-26.72,13.021-41.896
			l-30.803-251.56c-3.276-26.753-26.071-46.926-53.024-46.926h-72.096v-39.186C344.77,112.478,374.563,104.261,400.987,90.342z
			 M386.172,184.106c11.562,0,21.339,8.653,22.745,20.13l30.803,251.56c0.808,6.605-1.174,12.987-5.586,17.972
			c-4.411,4.983-10.505,7.728-17.159,7.728H95.032c-6.655,0-12.749-2.745-17.159-7.729c-4.411-4.983-6.395-11.365-5.586-17.971
			l30.803-251.56c1.405-11.475,11.184-20.129,22.746-20.129H386.172z M228.428,153.601v-38.732h55.143v38.732H228.428z
			 M299.959,84.364H212.04c-51.716,0-99.163-20.114-129.937-53.858h347.793C399.122,64.249,351.675,84.364,299.959,84.364z"/>

                        </g>
                        <g>
                          <g>
                            <path d="M256.004,207.803c-68.924,0-124.997,56.074-124.997,124.997c0,68.924,56.074,124.998,124.997,124.998
			s124.997-56.074,124.997-124.998C381.001,263.876,324.928,207.803,256.004,207.803z M256.004,427.292
			c-52.103,0-94.491-42.389-94.491-94.492c0-52.102,42.389-94.491,94.491-94.491c52.102,0,94.491,42.389,94.491,94.491
			C350.495,384.902,308.106,427.292,256.004,427.292z"/>
                          </g>
                        </g>
                        <g>
                          <g>
                            <path d="M266.789,322.015c-5.956-5.957-15.614-5.957-21.569,0l-36.724,36.724c-5.957,5.956-5.957,15.614,0,21.57
			c2.978,2.978,6.882,4.468,10.785,4.468s7.807-1.49,10.785-4.468l36.724-36.724C272.745,337.629,272.745,327.971,266.789,322.015z"
                            />
                          </g>
                        </g>
                        <g>
                        </g>
                        <g>
                        </g>
                        <g>
                        </g>
                        <g>
                        </g>
                        <g>
                        </g>
                        <g>
                        </g>
                        <g>
                        </g>
                        <g>
                        </g>
                        <g>
                        </g>
                        <g>
                        </g>
                        <g>
                        </g>
                        <g>
                        </g>
                        <g>
                        </g>
                        <g>
                        </g>
                        <g>
                        </g>
                      </svg>


                    </span>
                    <span className="nav-text">
                      {<FormattedMessage id="weigheted_products" />}
                    </span>
                  </NavLink>
                </li>
              )}



              {findRouteAccess(ModuleType.TenantManagement) && (
                <li>
                  <NavLink
                    title={formatMessage("user_title")}
                    to={RoutesConstants.SystemUser}
                  >
                    <span className="svgWidth">
                      <svg
                        clipRule="evenodd"
                        fillRule="evenodd"
                        fill="rgb(134, 137, 144)"
                        height="24"
                        width="24"
                        strokeLinejoin="round"
                        strokeMiterlimit="2"
                        viewBox="0 0 24 24"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <g id="Icon">
                          <path d="m11.5 20.263h-8.55c-.053 0-.104-.022-.141-.059-.038-.038-.059-.088-.059-.141 0-.001 0-1.451 0-1.451 0-.83.593-1.562 1.507-2.184 1.632-1.114 4.273-1.816 7.243-1.816.414 0 .75-.336.75-.75 0-.413-.336-.75-.75-.75-3.322 0-6.263.831-8.089 2.076-1.393.95-2.161 2.157-2.161 3.424v1.451c0 .45.179.883.498 1.202.319.318.751.498 1.202.498h8.55c.414-.001.75-.337.75-.751 0-.413-.336-.75-.75-.749z" />
                          <path d="m11.5 1.25c-3.036 0-5.5 2.464-5.5 5.5s2.464 5.5 5.5 5.5 5.5-2.464 5.5-5.5-2.464-5.5-5.5-5.5zm0 1.5c2.208 0 4 1.792 4 4s-1.792 4-4 4-4-1.792-4-4 1.792-4 4-4z" />
                          <path d="m17.5 13.938c-1.966 0-3.562 1.596-3.562 3.562s1.596 3.563 3.562 3.563 3.563-1.597 3.563-3.563-1.597-3.562-3.563-3.562zm0 1.5c1.138 0 2.063.924 2.063 2.062s-.925 2.063-2.063 2.063-2.063-.925-2.063-2.063.925-2.062 2.063-2.062z" />
                          <path d="m18.25 14.687v-1.687c0-.414-.336-.75-.75-.75s-.75.336-.75.75v1.688c0 .413.336.75.75.75.414-.001.75-.337.75-.751z" />
                          <path d="m20.019 16.042 1.193-1.194c.293-.292.293-.768 0-1.06-.292-.293-.768-.293-1.06 0l-1.194 1.193c-.292.293-.292.768 0 1.061.293.292.768.292 1.061 0z" />
                          <path d="m20.312 18.25h1.688c.414 0 .75-.336.75-.75s-.336-.75-.75-.75h-1.688c-.413 0-.749.336-.749.75-.001.414.336.75.749.75z" />
                          <path d="m18.958 20.019 1.194 1.193c.292.293.768.293 1.06 0 .293-.292.293-.768 0-1.06l-1.193-1.194c-.293-.292-.768-.292-1.061 0-.292.293-.292.768 0 1.061z" />
                          <path d="m16.75 20.312v1.688c0 .414.336.75.75.75s.75-.336.75-.75v-1.688c0-.413-.336-.749-.75-.75-.414 0-.75.337-.75.75z" />
                          <path d="m14.981 18.958-1.193 1.194c-.293.292-.293.768 0 1.06.292.293.768.293 1.06 0l1.194-1.193c.292-.293.292-.768 0-1.061-.293-.292-.768-.292-1.061 0z" />
                          <path d="m14.687 16.75h-1.687c-.414 0-.75.336-.75.75s.336.75.75.75h1.687c.414 0 .751-.336.75-.75 0-.414-.336-.75-.75-.75z" />
                          <path d="m16.042 14.981-1.194-1.193c-.292-.293-.768-.293-1.06 0-.293.292-.293.768 0 1.06l1.193 1.194c.293.292.768.292 1.061 0 .292-.293.292-.768 0-1.061z" />
                        </g>
                      </svg>
                    </span>
                    <span className="nav-text">
                      {<FormattedMessage id="user_title" />}
                    </span>
                  </NavLink>
                </li>
              )}

              {isCompany && (
                <React.Fragment>
                  {findRouteAccess(ModuleType.CompanyUserManagement) && (
                    <li title={formatMessage("system_user")}>
                      <NavLink to={RoutesConstants.SystemUser}>
                        <span className="svgWidth">
                          <svg
                            clipRule="evenodd"
                            fillRule="evenodd"
                            fill="rgb(134, 137, 144)"
                            height="22"
                            width="22"
                            strokeLinejoin="round"
                            strokeMiterlimit="2"
                            viewBox="0 0 24 24"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <g id="Icon">
                              <path d="m11.5 20.263h-8.55c-.053 0-.104-.022-.141-.059-.038-.038-.059-.088-.059-.141 0-.001 0-1.451 0-1.451 0-.83.593-1.562 1.507-2.184 1.632-1.114 4.273-1.816 7.243-1.816.414 0 .75-.336.75-.75 0-.413-.336-.75-.75-.75-3.322 0-6.263.831-8.089 2.076-1.393.95-2.161 2.157-2.161 3.424v1.451c0 .45.179.883.498 1.202.319.318.751.498 1.202.498h8.55c.414-.001.75-.337.75-.751 0-.413-.336-.75-.75-.749z" />
                              <path d="m11.5 1.25c-3.036 0-5.5 2.464-5.5 5.5s2.464 5.5 5.5 5.5 5.5-2.464 5.5-5.5-2.464-5.5-5.5-5.5zm0 1.5c2.208 0 4 1.792 4 4s-1.792 4-4 4-4-1.792-4-4 1.792-4 4-4z" />
                              <path d="m17.5 13.938c-1.966 0-3.562 1.596-3.562 3.562s1.596 3.563 3.562 3.563 3.563-1.597 3.563-3.563-1.597-3.562-3.563-3.562zm0 1.5c1.138 0 2.063.924 2.063 2.062s-.925 2.063-2.063 2.063-2.063-.925-2.063-2.063.925-2.062 2.063-2.062z" />
                              <path d="m18.25 14.687v-1.687c0-.414-.336-.75-.75-.75s-.75.336-.75.75v1.688c0 .413.336.75.75.75.414-.001.75-.337.75-.751z" />
                              <path d="m20.019 16.042 1.193-1.194c.293-.292.293-.768 0-1.06-.292-.293-.768-.293-1.06 0l-1.194 1.193c-.292.293-.292.768 0 1.061.293.292.768.292 1.061 0z" />
                              <path d="m20.312 18.25h1.688c.414 0 .75-.336.75-.75s-.336-.75-.75-.75h-1.688c-.413 0-.749.336-.749.75-.001.414.336.75.749.75z" />
                              <path d="m18.958 20.019 1.194 1.193c.292.293.768.293 1.06 0 .293-.292.293-.768 0-1.06l-1.193-1.194c-.293-.292-.768-.292-1.061 0-.292.293-.292.768 0 1.061z" />
                              <path d="m16.75 20.312v1.688c0 .414.336.75.75.75s.75-.336.75-.75v-1.688c0-.413-.336-.749-.75-.75-.414 0-.75.337-.75.75z" />
                              <path d="m14.981 18.958-1.193 1.194c-.293.292-.293.768 0 1.06.292.293.768.293 1.06 0l1.194-1.193c.292-.293.292-.768 0-1.061-.293-.292-.768-.292-1.061 0z" />
                              <path d="m14.687 16.75h-1.687c-.414 0-.75.336-.75.75s.336.75.75.75h1.687c.414 0 .751-.336.75-.75 0-.414-.336-.75-.75-.75z" />
                              <path d="m16.042 14.981-1.194-1.193c-.292-.293-.768-.293-1.06 0-.293.292-.293.768 0 1.06l1.193 1.194c.293.292.768.292 1.061 0 .292-.293.292-.768 0-1.061z" />
                            </g>
                          </svg>
                        </span>
                        <span className="nav-text">
                          {<FormattedMessage id="system_user" />}
                        </span>
                      </NavLink>
                    </li>
                  )}
                </React.Fragment>
              )}

              {getIsTenantAccessFromLocalStorage() == true &&
                (getUserType === userTypeEnum[4] ||
                  getUserType === userTypeEnum[0] ||
                  getUserType === userTypeEnum[1]) ? (
                <React.Fragment>
                  {getIsTenantAccessFromLocalStorage() == true && (
                    <li title={formatMessage("exit_tenant")} id="exit">
                      <a
                        className="btn btn-light-danger"
                        data-toggle="modal"
                        onClick={() => exitTenant()}
                      >
                        <span className="svgWidth">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="22"
                            height="22"
                            viewBox="0 0 24 24"
                            fill="none"
                            stroke="currentColor"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          >
                            <path d="M9 21H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h4"></path>
                            <polyline points="16 17 21 12 16 7"></polyline>
                            <line x1="21" y1="12" x2="9" y2="12"></line>
                          </svg>
                        </span>
                        <span className="nav-text">
                          <FormattedMessage id="exit_tenant" />{" "}
                        </span>
                      </a>
                    </li>
                  )}
                </React.Fragment>
              ) : (
                ""
              )}
            </ul>
          </nav>
        </div>
        <div className="aside-footer">
          <ul>
            <li title={formatMessage("Profile")} onClick={props.UserProfileToggleHandler}>
              <span className="circle_icons">
                <svg
                  fill="none"
                  height="20"
                  viewBox="0 0 24 24"
                  width="20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g clipRule="evenodd" fill="currentColor" fillRule="evenodd">
                    <path d="m17.0386 16.126c-3.0811-1.8347-6.9962-1.8347-10.07738 0-.16791.1-.35189.2043-.5446.3137-.71261.4043-1.54463.8763-2.12472 1.4441-.36013.3525-.51364.6433-.5382.8679-.0195.1784.0256.4725.47623.9018 1.03585.9869 2.08842 1.5965 3.36087 1.5965h8.8183c1.2724 0 2.325-.6096 3.3608-1.5965.4507-.4293.4958-.7234.4763-.9018-.0246-.2246-.1781-.5154-.5382-.8679-.5801-.5678-1.4121-1.0398-2.1248-1.4441-.1927-.1094-.3767-.2137-.5446-.3137zm.7675-1.2888c.1163.0692.26.1503.4226.2421.7128.4021 1.7903 1.01 2.5285 1.7325.4617.4519.9003 1.0474.9801 1.777.0848.7758-.2537 1.5039-.9327 2.1508-1.1714 1.116-2.5772 2.0104-4.3955 2.0104h-8.8183c-1.81831 0-3.22409-.8944-4.39554-2.0104-.67902-.6469-1.01748-1.375-.93268-2.1508.07975-.7296.5184-1.3251.98007-1.777.73817-.7225 1.81568-1.3304 2.52849-1.7325.16267-.0918.30634-.1729.42266-.2421 3.55405-2.1163 8.0582-2.1163 11.6123 0z" />
                    <path d="m11.9999 2.75c-2.07104 0-3.74997 1.67893-3.74997 3.75s1.67893 3.75 3.74997 3.75c2.0711 0 3.75-1.67893 3.75-3.75s-1.6789-3.75-3.75-3.75zm-5.24997 3.75c0-2.8995 2.35051-5.25 5.24997-5.25 2.8995 0 5.25 2.3505 5.25 5.25 0 2.89949-2.3505 5.25-5.25 5.25-2.89946 0-5.24997-2.35051-5.24997-5.25z" />
                  </g>
                </svg>
              </span>
              <span className="nav-text">
                <FormattedMessage id="user_profile" />
              </span>
            </li>
            <li data-testid="Logout" title={formatMessage("logout")} onClick={props.logoutPopupToggleHandler}>
              <span className="circle_icons">
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="currentColor"><path fill="currentColor" d="M13.24 2c.47 0 .86.38.86.86v18.29c0 .47-.38.86-.86.86-5.89 0-10-4.11-10-10S7.36 2 13.24 2z" opacity=".4"></path><path fill="currentColor" d="M20.54 11.54L17.7 8.69a.754.754 0 00-1.06 0c-.29.29-.29.77 0 1.06l1.56 1.56H8.63c-.41 0-.75.34-.75.75s.34.75.75.75h9.57l-1.56 1.56c-.29.29-.29.77 0 1.06.15.15.34.22.53.22s.38-.07.53-.22l2.84-2.85a.73.73 0 000-1.04z"></path>
                </svg>
              </span>
              <span className="nav-text"> Logout</span>
            </li>
            <li>

            </li>
          </ul>
        </div>
      </aside>
    </React.Fragment>
  );
};

export default UserProfilePopupHOC(
  LogoutPopupHOC(injectIntl(observer(LeftNavBar)))
);
