/*
<summary>
   This component is Used for Activating Kms Account.
   Developer: Mohammad Saquib Khan, Created Date:14-May-2024
</summary>
<param>No Parameter Passed</param>
<returns>Returns JSX</returns>
*/

import "./account-activation.css";
import { observer } from "mobx-react";
import React, { useEffect } from "react";
import { FormattedMessage } from "react-intl";
import "react-sliding-side-panel/lib/index.css";
import EmailMessageIcon from "../../assets/Images/emailVerification.png";
import { formatMessage } from "../../translations/format-message";
import FormLoader from "../../shared-components/FormLoader/FormLoader";
import { useLocation } from "react-router-dom";
import { useStore } from "../../contexts/store-provider";
import accountStatusEnum from "../../constants/enums/account-status-type-enum";

const AccountActivation = (props: any) => {
  const { userStore } = useStore();
  const {
    ActivateUserService,
    activateUserAccountState,
    resetAccountActivationState,
  } = userStore;
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const userId = params.get("userId");
  const email = params.get("email");

  useEffect(() => {
    resetAccountActivationState();
    if (userId && email) {
      ActivateUserService(Number(userId), email, accountStatusEnum.Active);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <React.Fragment>
      {<FormLoader loading={activateUserAccountState.inProgress} />}

      <div className="message email">
        <div className="row text-center">
          <div className="col-lg-12">
            <img src={EmailMessageIcon} alt="" className="no_message EmailMessageIcon" />
          </div>
          <div className="col-lg-12 mt-3 p-3">
            <h3 className="fw-bold display-6">
              {activateUserAccountState.success && <FormattedMessage id="activating_account" />}
            </h3>
            {activateUserAccountState.error != "" ? (
              <p className="mb-3">
                {formatMessage(activateUserAccountState.error)}
              </p>
            ) : (
              ""
            )}
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default observer(AccountActivation);
