/*
<summary>
  This functional component "ImagePopupHOC" provides props to the child component.
  1. State "showImagePopup" that manages the show/hide of pop up
  2. Function "imageShowToggleHandler" to toggle the show/hide popup state "showImagePopup"
  Developer:Raman Sharma, Created Date:04-Sep-2024
</summary>
<returns>Returns JSX</returns>
*/

import React, { useState } from 'react';

const ImagePopupHOC = (Component: any) =>
  function Comp(props: any) {
    const [showImagePopup, setShowImagePopup] = useState<boolean>(false);

    const imageShowToggleHandler = () => {
      setShowImagePopup(!showImagePopup);
    }

    return <Component showImagePopup={showImagePopup} imageShowToggleHandler={imageShowToggleHandler} {...props} />;
  };

export default ImagePopupHOC;