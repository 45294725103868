/*
<summary>
  This functional component "ChangePasswordForm" provides form to change the password of the selected user.
  Developer:Aashish Singh, Created Date:29-August-2023
</summary>
<returns>Returns JSX</returns>
*/
import { useState } from "react";
import { Form, Formik, Field } from "formik";
import { FormattedMessage } from "react-intl";
import { Modal, Button } from "react-bootstrap";
import FormikFormInput from "../../../shared-components/FormikFormHandlers/FormikFormInput";
import { UserChangePasswordValidateSchema } from "../../../shared-components/FormikFormHandlers/FormikValidationSchema";
import { formatMessage } from "../../../translations/format-message";
import LoaderButton from "../../../shared-components/button/loader-button";
import Label from "../../../shared-components/label/label";
import { IChangePassword } from "../../../models/forms/IChangePassword";
import validCharacterPassword from "../../../constants/valid-character-password";

interface IProps {
  id: number;
  submitHandler: (data: any) => void;
  onModalClosed: () => void;
  loading: boolean;
  initialValues: IChangePassword;
}

const ChangePasswordForm = (props: IProps) => {
  const [showHidePassword, setShowHidePassword] = useState(false);
  const [showHideConfirmPassword, setShowHideConfirmPassword] = useState(false);

  return (
    <>
      <Modal
        className="changePasswordModal  fancy-modal"
        show={true}
        centered
        onHide={() => {}}
      >
        <Modal.Header>
          <Modal.Title>
            <button
              title={formatMessage("close")}
              onClick={props.onModalClosed}
              disabled={props.loading}
              className="Crossicons"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="23"
                height="23"
                fill="currentColor"
                className="bi bi-x"
                viewBox="0 0 16 16"
              >
                <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
              </svg>
            </button>
            <FormattedMessage id="change_password" />
          </Modal.Title>
        </Modal.Header>
        <Formik
          initialValues={props.initialValues}
          enableReinitialize
          validationSchema={UserChangePasswordValidateSchema}
          validateOnBlur={false}
          onSubmit={async (values) => {
            props.submitHandler(values);
          }}
        >
          <Form className="change_password-form">
            <Modal.Body>
              <span className="modal-wave">
                <svg
                  width="447"
                  height="116"
                  viewBox="0 0 447 116"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    opacity="0.4"
                    d="M55.2678 22.3777C-49.5465 -14.1611 7.16534 -48.8529 136.242 -34.0647L214.579 -30.0724L448.26 -8.82579L459.956 104.858C396.401 148.386 406.862 51.7166 297.501 67.1292C188.139 82.5419 225.278 33.322 176.928 20.0906C128.579 6.8592 91.4243 34.9821 55.2678 22.3777Z"
                    fill="rgba(126, 166, 255, 0.4)"
                  ></path>
                </svg>
              </span>
              <div className="col-md-12 col-12 password-icon">
                <div className="form-group">
                  <Label
                    className="label-style"
                    required={true}
                    label="new_password"
                  />
                  <div>
                    <Field
                      data-testid="testPassword"
                      className="form-control"
                      name="NewPassword"
                      autoComplete="new-password"
                      type={showHidePassword ? "text" : "password"}
                      invalidcharacter={[" "]}
                      validcharacter={validCharacterPassword}
                      placeholder=""
                      as={FormikFormInput}
                    />
                    {showHidePassword ? (
                      <svg
                        type="button"
                        onClick={() => {
                          setShowHidePassword(!showHidePassword);
                        }}
                        viewBox="0 0 24 24"
                        width="20"
                        height="20"
                        stroke="currentColor"
                        strokeWidth="2"
                        fill="none"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        className="showHidePass css-i6dzq1 modalBox"
                      >
                        <path d="M1 12s4-8 11-8 11 8 11 8-4 8-11 8-11-8-11-8z"></path>
                        <circle cx="12" cy="12" r="3"></circle>
                      </svg>
                    ) : (
                      <svg
                        type="button"
                        onClick={() => {
                          setShowHidePassword(!showHidePassword);
                        }}
                        viewBox="0 0 24 24"
                        width="20"
                        height="20"
                        stroke="currentColor"
                        strokeWidth="2"
                        fill="none"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        className="showHidePass css-i6dzq1 modalBox"
                      >
                        <path d="M17.94 17.94A10.07 10.07 0 0 1 12 20c-7 0-11-8-11-8a18.45 18.45 0 0 1 5.06-5.94M9.9 4.24A9.12 9.12 0 0 1 12 4c7 0 11 8 11 8a18.5 18.5 0 0 1-2.16 3.19m-6.72-1.07a3 3 0 1 1-4.24-4.24"></path>
                        <line x1="1" y1="1" x2="23" y2="23"></line>
                      </svg>
                    )}
                  </div>
                </div>
              </div>
              <div className="col-md-12 col-12 password-icon">
                <div className="form-group">
                  <Label
                    className="label-style"
                    required={true}
                    label="confirm_password"
                  />
                  <div>
                    <Field
                      data-testid="testConfirmPassword"
                      className="form-control"
                      name="ConfirmPassword"
                      autoComplete="confirm-new-password"
                      type={showHideConfirmPassword ? "text" : "password"}
                      invalidcharacter={[" "]}
                      validcharacter={validCharacterPassword}
                      placeholder=""
                      as={FormikFormInput}
                    />
                    {showHideConfirmPassword ? (
                      <svg
                        type="button"
                        onClick={() => {
                          setShowHideConfirmPassword(!showHideConfirmPassword);
                        }}
                        viewBox="0 0 24 24"
                        width="20"
                        height="20"
                        stroke="currentColor"
                        strokeWidth="2"
                        fill="none"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        className="showHidePass css-i6dzq1 modalBox"
                      >
                        <path d="M1 12s4-8 11-8 11 8 11 8-4 8-11 8-11-8-11-8z"></path>
                        <circle cx="12" cy="12" r="3"></circle>
                      </svg>
                    ) : (
                      <svg
                        type="button"
                        onClick={() => {
                          setShowHideConfirmPassword(!showHideConfirmPassword);
                        }}
                        viewBox="0 0 24 24"
                        width="20"
                        height="20"
                        stroke="currentColor"
                        strokeWidth="2"
                        fill="none"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        className="showHidePass css-i6dzq1 modalBox"
                      >
                        <path d="M17.94 17.94A10.07 10.07 0 0 1 12 20c-7 0-11-8-11-8a18.45 18.45 0 0 1 5.06-5.94M9.9 4.24A9.12 9.12 0 0 1 12 4c7 0 11 8 11 8a18.5 18.5 0 0 1-2.16 3.19m-6.72-1.07a3 3 0 1 1-4.24-4.24"></path>
                        <line x1="1" y1="1" x2="23" y2="23"></line>
                      </svg>
                    )}
                  </div>
                </div>
              </div>
              <span className="footer-wave">
                <svg
                  width="333"
                  height="61"
                  viewBox="0 0 333 61"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    opacity="0.4"
                    d="M-0.322477 0.641086L-0.418408 0.55164L-9.20939 59.4297L23.6588 106.206L154.575 130.423C236.759 117.931 383.93 93.3326 315.142 94.879C246.355 96.4253 215.362 64.2785 215.362 64.2785C215.362 64.2785 185.497 26.9117 117.864 33.4279C42.6115 40.6783 10.6143 10.8399 -0.322477 0.641086Z"
                    fill="rgba(126, 166, 255, 0.4)"
                  ></path>
                </svg>
              </span>
            </Modal.Body>
            <div className="modal-footer pt-0 border-0">
              <div className="form-group row g-0 tab-action">
                <div className="col-12">
                  <Button
                    variant="light-danger"
                    type="button"
                    disabled={props.loading}
                    onClick={props.onModalClosed}
                  >
                    <FormattedMessage id="button_cancel" />
                  </Button>
                  <LoaderButton
                    type="submit"
                    isLoading={props.loading}
                    text="button_save"
                  />
                </div>
              </div>
            </div>
          </Form>
        </Formik>
      </Modal>
    </>
  );
};

export default ChangePasswordForm;
