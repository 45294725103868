/*
<summary>
  This functional component "AddPopupHOC" provides props to the child component.
  1. State "showAddPopup" that manages the show/hide of pop up
  2. Function "addToggleHandler" to toggle the show/hide popup state "showAddPopup"
  Developer:Raman Sharma, Created Date:16-August-2024
</summary>
<returns>Returns JSX</returns>
*/
import React, { useState } from "react";

const AddProductPopupHOC = (Component: any) =>
  function Comp(props: any) {
    const [showAddProductPopup, setShowAddProductPopup] = useState<boolean>(false);

    const addToggleHandler = () => {
      setShowAddProductPopup(!showAddProductPopup);
    };

    return (
      <Component
        showAddProductPopup={showAddProductPopup}
        addToggleHandler={addToggleHandler}
        {...props}
      />
    );
  };

export default AddProductPopupHOC;
