/*
<summary>
  This functional component shows the configuration details.
  Developer:Aashish Singh, Created Date: 09-Oct-2023
</summary>
<returns>Returns JSX</returns>
*/

import React, { useEffect } from "react";
import { observer } from "mobx-react";
import { FormattedMessage } from "react-intl";
import { useStore } from "../../contexts/store-provider";

import "./tenant.css";
interface IProps {
  selectedData: any;
}

const TenantDetails = (props: IProps) => {
  const { tenantStore } = useStore();
  const { tenantDetails, GetTenantLocalService } = tenantStore;
  const tenant: any = tenantDetails

  useEffect(() => {
    GetTenantLocalService(props.selectedData.TenantId)
  }, [props.selectedData.TenantId])


  return (
    <React.Fragment>
      <div className="px-4  py-3">
        <div className="row">
          <div className="col-lg-6">
            <h3 className="m-0"> <FormattedMessage id={"tenant_name"} />{" "}</h3></div>
          <div className="col-lg-6">
            <p className="mw-100 mb-0 text-muted text-end"> {tenant.OrganizationName}</p></div>
        </div>

        <hr />
        <div className="row">
          <div className="col-lg-6">
            <h3 className="m-0">   <FormattedMessage id={"tenant_nickname"} />{" "}</h3></div>
          <div className="col-lg-6">
            <p className="mw-100 mb-0 text-muted text-end"> {tenant.OrganizationNickName}</p>
          </div>
        </div>
        <hr />
        <div className="row">
          <div className="col-lg-6">
            <h3 className="m-0">  <FormattedMessage id={"phone"} />{" "}</h3></div>
          <div className="col-lg-6">
            <p className="mw-100 mb-0 text-muted text-end">  {tenant.Phone}</p>
          </div>
        </div>
        <hr />
        <div className="row">
          <div className="col-12">
            <h3 className="m-0"> <FormattedMessage id={"address"} />{" "}</h3>
            <p className="mw-100 mb-0 text-muted mt-2">  {tenant.Address}</p>
          </div>
        </div>
        <hr />
        <div className="row">
          <div className="col-12">
            <h3 className="m-0"> <FormattedMessage id={"description"} />{" "}</h3>
            <p className="mw-100 mb-0 text-muted mt-2"> {tenant.Description}</p>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default observer(TenantDetails);
