/*
<summary>
   This functional component provides us a button with spinner that shows the loading.
   Developer:Aashish Singh, Created Date:29-March-2023
</summary>
<returns>Returns JSX</returns>
*/
import React from 'react';
import Loader from '../loader/loader';
import { Button } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';
import { formatMessage } from '../../translations/format-message';

const LoaderButton = (props: any) => {
  return (
    <React.Fragment>
      <Button
        onClick={props.onClick}
        disabled={props.disabled || props.isLoading}
        type={props.type}
        title={props.title ? formatMessage(props.title) : undefined}
        className={`btn btn-blue ${props.className ? props.className : ""}`}>
        <FormattedMessage id={props.text} />
        {props.isLoading ? <Loader /> : null}
      </Button>
    </React.Fragment>
  );
}

export default LoaderButton


