/*
<summary>
   This class component is all about Managing common functionalities (i.e. language, version).
   Developer:Aashish Singh, Created Date:29-March-2023
</summary>
<param>No Parameter Passed</param>
<returns>Returns class instance</returns>
*/
import { action, computed, makeObservable, observable } from "mobx";
import URLConstants from "../../constants/url-constants";
import * as baseService from "../service/base-service";
import { IPreferenceState } from "../../models/state/IPreferenceState";
import { ICommonState } from "../../models/state/ICommonState";
import IApiResponse, {
  IApiSuccessResponse,
} from "../../models/response/IApiResponse";
import {
  IAccessCamera,
  IAccessMedia,
  IAccessPriceScheduler,
  IAccessTenant,
  IParkingLotFilter,
  ISystemLogsFilter,
  IUserLogsFilter,
} from "../../models/ICommon";
import { getTenantIdFromToken } from "../../helpers/localstorage-helper";

export class PreferencesStore implements IPreferenceState, ICommonState {
  inProgress = false;
  error = "";
  lang =
    localStorage.getItem("kms_language") !== null
      ? localStorage.getItem("kms_language")
      : "en";
  language = this.lang!;
  version = "";
  userSetting: any = {
    pageLimitConfig:
      localStorage.getItem("kms_pageLimitConfig") !== null
        ? parseInt(localStorage.getItem("kms_pageLimitConfig")!)
        : 100,
    tagPageLimitConfig:
      localStorage.getItem("kms_tagPageLimitConfig") !== null
        ? parseInt(localStorage.getItem("kms_tagPageLimitConfig")!)
        : 10,
  };

  productSetting: any = {
    pageLimitConfig:
      localStorage.getItem("kms_pageLimitConfig") !== null
        ? parseInt(localStorage.getItem("kms_pageLimitConfig")!)
        : 100,
    tagPageLimitConfig:
      localStorage.getItem("kms_tagPageLimitConfig") !== null
        ? parseInt(localStorage.getItem("kms_tagPageLimitConfig")!)
        : 10,
  };

  islanguageChanged = false;

  tenantAccess: IAccessTenant = {
    isAccessTenantActive:
      localStorage.getItem("kms_is_access_tenant") !== null
        ? JSON.parse(localStorage.getItem("kms_is_access_tenant")!)
        : false,
    tenantId:
      (localStorage.getItem("kms_access_tenant_id") !== null && localStorage.getItem("kms_access_tenant_id") !== "0")
        ? Number(localStorage.getItem("kms_access_tenant_id"))
        : getTenantIdFromToken(),
    tenantName:
      localStorage.getItem("kms_access_tenant_name") !== null
        ? localStorage.getItem("kms_access_tenant_name")
        : "",
    orgId:
      localStorage.getItem("kms_access_org_id") !== null
        ? localStorage.getItem("kms_access_org_id")
        : "",
  };

  // parkingLotCameraAccess: IAccessCamera = {
  //   plId:
  //     localStorage.getItem("kms_access_parking_id") !== null
  //       ? Number(localStorage.getItem("kms_access_parking_id"))
  //       : 0,
  //   parkingLotName:
  //     localStorage.getItem("kms_access_parking_name") !== null
  //       ? localStorage.getItem("kms_access_parking_name")
  //       : "",
  //   isAccessCameraActive:
  //     localStorage.getItem("kms_is_access_camera") !== null
  //       ? JSON.parse(localStorage.getItem("kms_is_access_camera")!)
  //       : false,
  // };

  managePriceSchedulerAccess: IAccessPriceScheduler = {
    plId:
      localStorage.getItem("kms_access_parking_id") !== null
        ? Number(localStorage.getItem("kms_access_parking_id"))
        : 0,
    parkingLotName:
      localStorage.getItem("kms_access_parking_name") !== null
        ? localStorage.getItem("kms_access_parking_name")
        : "",
    isAccessPriceSchedulerActive:
      localStorage.getItem("kms_is_access_price_scheduler") !== null
        ? JSON.parse(localStorage.getItem("kms_is_access_price_scheduler")!)
        : false,
  };

  manageMediaAccess: IAccessMedia = {
    folderId:
      localStorage.getItem("kms_access_folder_id") !== null
        ? Number(localStorage.getItem("kms_access_folder_id"))
        : 0,
    folderName:
      localStorage.getItem("kms_access_folder_name") !== null
        ? localStorage.getItem("kms_access_folder_name")
        : "",
    isAccessMediaManagementActive:
      localStorage.getItem("kms_is_access_media_management") !== null
        ? JSON.parse(localStorage.getItem("kms_is_access_media_management")!)
        : false,
  };

  isSpecialSchedulerActive: boolean = localStorage.getItem("kms_is_special_schedule_active") !== null ? JSON.parse(localStorage.getItem("kms_is_special_schedule_active")!) : false;

  parkingLotFilter: IParkingLotFilter = {
    ParkingLotName: "",
    ParkingLotId: "",
    Status: -1,
    IsFilterActive: false
  }

  userLogsFilter: IUserLogsFilter = {
    UserId: "",
    Company: "",
    IsFilterActive: false
  }

  systemLogsFilter: ISystemLogsFilter = {
    UserId: "",
    BranchName: "",
    ParkingLotName: "",
    IsFilterActive: false
  }

  constructor() {
    makeObservable(this, {
      language: observable,
      version: observable,
      userSetting: observable,
      tenantAccess: observable,
      manageMediaAccess: observable,
      managePriceSchedulerAccess: observable,
      // parkingLotCameraAccess: observable,
      isSpecialSchedulerActive: observable,
      parkingLotFilter: observable,
      userLogsFilter: observable,
      systemLogsFilter: observable,
      islanguageChanged: false,
      getLanguage: computed,
      getVersionFromStore: computed,
      changeLanguage: action,
      UpdateLanguage: action,
      updateLanguageChangedFlag: action,
      setUserSetting: action,
      setProductSetting: action,
      setTenantAccess: action,
      setParkingFilter: action,
      setUserLogsFilter: action,
      setIsSpecialSchedulerActive: action,
      // setParkingLotCameraAccess: action,
      setManageMediaAccess: action,
      setManagePriceSchedulerAccess: action,
      setSystemLogsFilter: action,
      resetPriceSchedulerAccess: action,
      // resetParkingLotCameraAccess: action,
      resetUnwantedModules: action,
      resetMediaAccess: action,
      getTenantAccess: computed,
      // getParkingLotCameraAccess: computed,
      // getMediaAccess: computed,
      // getPriceSchedulerAccess: computed,
      getIsSpecialScheduleActive: computed,
      getParkingLotFilter: computed,
      getUserLogsFilter: computed,
      getSystemLogsFilter: computed
    });
  }

  get getLanguage() {
    return this.language;
  }

  get getTenantAccess() {
    return this.tenantAccess;
  }

  // get getParkingLotCameraAccess() {
  //   return this.parkingLotCameraAccess;
  // }

  get getMediaAccess() {
    return this.manageMediaAccess;
  }

  get getPriceSchedulerAccess() {
    return this.managePriceSchedulerAccess;
  }

  get getIsSpecialScheduleActive() {
    return this.isSpecialSchedulerActive;
  }

  get getParkingLotFilter() {
    return this.parkingLotFilter;
  }

  get getUserLogsFilter() {
    return this.userLogsFilter;
  }

  get getSystemLogsFilter() {
    return this.systemLogsFilter;
  }

  /*
    This function is used to Change Language.
    */

  changeLanguage(language: string) {
    this.islanguageChanged = this.language === language ? false : true;
    this.language = language;
    localStorage.setItem("kms_language", language);
  }

  /*
    This function Updates language with the language getting from local Storage.
    */

  UpdateLanguage() {
    let language = localStorage.getItem("kms_language");
    if (language) this.language = language;
  }

  /*
    This function is used to set Language Changed Flag.
    */
  updateLanguageChangedFlag = () => {
    this.islanguageChanged = false;
  };

  /*
    This function is used to Get Version from API.
    */

  getVersion = () => {
    this.inProgress = true;
    const url = URLConstants.Version;
    return baseService
      .getRequest(url)
      .then((response: IApiResponse<string>) => {
        let data = Number(response.data).toFixed(1);
        this.version = data;
        localStorage.setItem("kms_version", data);
      })
      .catch((err: string) => {
        this.error = err;
      })
      .finally(
        action(() => {
          this.inProgress = false;
        })
      );
  };

  get getVersionFromStore() {
    return this.version != ""
      ? this.version
      : localStorage.getItem("kms_version");
  }

  /*
    This function is used to set User config settings.
    */
  setUserSetting = (key: string, id: number) => {
    localStorage.setItem("kms_" + key, id.toString());
    this.userSetting[key] = id;
  };

  /*
  This function is used to set Product config settings.
  */
  setProductSetting = (key: string, id: number) => {
    localStorage.setItem("kms_" + key, id.toString());
    this.userSetting[key] = id;
  };

  setParkingFilter = (obj: IParkingLotFilter) => {
    this.parkingLotFilter = obj;
  }

  setUserLogsFilter = (obj: IUserLogsFilter) => {
    this.userLogsFilter = obj;
  }

  setSystemLogsFilter = (obj: ISystemLogsFilter) => {
    this.systemLogsFilter = obj;
  }

  setTenantAccess = (tenantAccessObj: IAccessTenant) => {
    localStorage.setItem(
      "kms_is_access_tenant",
      tenantAccessObj?.isAccessTenantActive.toString()
    );
    localStorage.setItem(
      "kms_access_tenant_id",
      tenantAccessObj.tenantId.toString()
    );
    localStorage.setItem(
      "kms_access_tenant_name",
      tenantAccessObj.tenantName != null ? tenantAccessObj.tenantName : ""
    );
    localStorage.setItem(
      "kms_access_org_id",
      tenantAccessObj.orgId != null ? tenantAccessObj.orgId : ""
    );
    this.tenantAccess = tenantAccessObj;
  };

  // setParkingLotCameraAccess = (parkingLotCameraAccessObj: IAccessCamera) => {
  //   localStorage.setItem(
  //     "kms_is_access_camera",
  //     parkingLotCameraAccessObj?.isAccessCameraActive?.toString()
  //   );
  //   localStorage.setItem(
  //     "kms_access_parking_id",
  //     parkingLotCameraAccessObj.plId.toString()
  //   );
  //   localStorage.setItem(
  //     "kms_access_parking_name",
  //     parkingLotCameraAccessObj.parkingLotName != null
  //       ? parkingLotCameraAccessObj.parkingLotName
  //       : ""
  //   );
  //   this.parkingLotCameraAccess = parkingLotCameraAccessObj;
  // };

  setManageMediaAccess = (mediaAccess: IAccessMedia) => {
    localStorage.setItem(
      "kms_is_access_media_management",
      mediaAccess?.isAccessMediaManagementActive?.toString()
    );
    localStorage.setItem("kms_access_folder_id", mediaAccess.folderId?.toString());
    localStorage.setItem(
      "kms_access_folder_name",
      mediaAccess.folderName != null ? mediaAccess.folderName : ""
    );
    this.manageMediaAccess = mediaAccess;
  };

  setManagePriceSchedulerAccess = (
    priceSchedulerAccess: IAccessPriceScheduler
  ) => {
    localStorage.setItem(
      "kms_is_access_price_scheduler",
      priceSchedulerAccess?.isAccessPriceSchedulerActive?.toString()
    );
    localStorage.setItem(
      "kms_access_parking_id",
      priceSchedulerAccess.plId.toString()
    );
    localStorage.setItem(
      "kms_access_parking_name",
      priceSchedulerAccess.parkingLotName != null
        ? priceSchedulerAccess.parkingLotName
        : ""
    );
    this.managePriceSchedulerAccess = priceSchedulerAccess;
  };

  setIsSpecialSchedulerActive = (value: boolean) => {
    localStorage.setItem("kms_is_special_schedule_active", value.toString());
  }

  // resetParkingLotCameraAccess = () => {
  //   this.parkingLotCameraAccess = {
  //     plId: 0,
  //     parkingLotName: "",
  //     isAccessCameraActive: false,
  //   };
  //   this.setParkingLotCameraAccess(this.parkingLotCameraAccess);
  // };

  resetPriceSchedulerAccess = () => {
    this.managePriceSchedulerAccess = {
      plId: 0,
      parkingLotName: "",
      isAccessPriceSchedulerActive: false,
    };
    this.setManagePriceSchedulerAccess(this.managePriceSchedulerAccess);
  };

  resetMediaAccess = () => {
    this.manageMediaAccess = {
      folderId: 0,
      folderName: "",
      isAccessMediaManagementActive: false,
    };
    this.setManageMediaAccess(this.manageMediaAccess);
  };

  resetUnwantedModules = () => {
    this.resetMediaAccess();
    this.resetPriceSchedulerAccess();
    // this.resetParkingLotCameraAccess();
  }
}

export default new PreferencesStore();
